// Settings.jsx
import React, { useState, useEffect } from 'react';
import {
  Paper, Button, Box, Typography, Snackbar, Alert, Checkbox, FormControlLabel,
} from '@mui/material';
import { getAuth, deleteUser } from 'firebase/auth';
import { getFirestore, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { app } from '../firebase';
import handleLogout from './handleLogout';
import { useAuth } from './AuthProvider';
import AudioSelector from './AudioSelector';

const Settings = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState('');
  const navigate = useNavigate();
  const db = getFirestore(app);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      // Fetch audio preference in the background
      getDoc(doc(db, "users", user.uid))
        .then(userDoc => {
          if (userDoc.exists() && userDoc.data().info?.audioPreference) {
            setSelectedAudio(userDoc.data().info.audioPreference);
          }
        })
        .catch(error => {
          console.error("Error fetching audio preference:", error);
        });
    }
  }, [user, db]);

  const handleDeleteAccount = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setIsDeleting(true);
      try {
        await new Promise(resolve => setTimeout(resolve, 2000));
        await deleteDoc(doc(db, 'users', user.uid));
        await deleteUser(user);
        setSnackbarMessage('Cuenta borrada con éxito.');
        setSnackbarSeverity('success');
        handleLogout(navigate);
      } catch (error) {
        setSnackbarMessage(`Error eliminando la cuenta: ${error.message}`);
        setSnackbarSeverity('error');
      } finally {
        setIsDeleting(false);
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCheckboxChange = (event) => {
    setConfirmDelete(event.target.checked);
  };

  return (
    <Paper sx={{ p: 3, borderRadius: 4, boxShadow: 3, mb: 3 }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ mb: 6 }}>
        Configuración
      </Typography>

      <AudioSelector 
        selectedAudio={selectedAudio}
        setSelectedAudio={setSelectedAudio}
        userId={user?.uid}
      />

      <Typography variant="h6" gutterBottom>
        Borrar cuenta
      </Typography>
      <FormControlLabel
        control={<Checkbox checked={confirmDelete} onChange={handleCheckboxChange} />}
        label="Estoy seguro de borrar mi cuenta. Esta acción es permanente."
      />
      <Box sx={{ mt: 2, mb: 4 }}>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteAccount}
          disabled={!confirmDelete || isDeleting}
        >
          {isDeleting ? 'Borrando...' : 'Borrar cuenta'}
        </Button>
      </Box>

      <Box sx={{ mt: 4, borderTop: 1, borderColor: 'divider', pt: 2 }}>
        <Typography variant="body2" color="text.secondary">
          Si necesitas ayuda con cualquier tema relacionado con tu cuenta, puedes<br />
          escribir a <strong>contacto@sessionfocus.com</strong>
        </Typography>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default Settings;