import React from 'react';
import { TextField } from '@mui/material';

const ProfileForm = ({ editedProfile, handleChange, validationErrors }) => (
  <>
    <TextField
      name="firstName"
      value={editedProfile.firstName}
      onChange={handleChange}
      label="Nombre"
      margin="normal"
      fullWidth
      error={!!validationErrors.firstName}
      helperText={validationErrors.firstName}
    />
    <TextField
      name="lastName"
      value={editedProfile.lastName}
      onChange={handleChange}
      label="Apellido"
      margin="normal"
      fullWidth
      error={!!validationErrors.lastName}
      helperText={validationErrors.lastName}
    />
  </>
);

export default ProfileForm;
