import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useRoomSituation from './useRoomSituation';
import useAddJoinedSessions from './useAddJoinedSessions';

export const useJoinParticipant = () => {
  const [status, setStatus] = useState({ loading: false, error: null, hasJoined: false });
  const navigate = useNavigate();
  const [sessionID, setSessionID] = useState(null);
  const room = useRoomSituation(sessionID);
  const [isJoining, setIsJoining] = useState(false);
  const addJoinedSession = useAddJoinedSessions();

  useEffect(() => {
    if (isJoining && room) {
      const joinSession = async () => {
        try {
          await addJoinedSession(sessionID);
          setStatus({ loading: false, error: null, hasJoined: true });
          navigate(`/sessionroom/${sessionID}${room}`);
        } catch (error) {
          setStatus({ loading: false, error: error.message, hasJoined: false });
          alert(`Error joining session: ${error.message}`);
        } finally {
          setIsJoining(false);
          setSessionID(null);
        }
      };

      joinSession();
    }
  }, [room, isJoining, navigate, sessionID, addJoinedSession]);

  const joinParticipant = useCallback(async (currentUser, session) => {
    setStatus({ loading: true, error: null, hasJoined: false });

    if (!currentUser) {
      alert("Por favor inicia sesión antes de entrar al evento.");
      setStatus({ loading: false, error: null, hasJoined: false });
      return;
    }

    try {
      if (!session || !session.id) {
        throw new Error("Invalid session data: session or session.id is undefined");
      }

      const sessionId = session.id;

      if (typeof sessionId !== "string" || sessionId.trim() === "") {
        throw new Error("Invalid session ID");
      }

      if (!currentUser || !currentUser.uid) {
        throw new Error("Invalid user data: currentUser or currentUser.uid is undefined");
      }

      console.log(`User ${currentUser.uid} validated for session ${sessionId}. Determining room.`);

      setSessionID(sessionId);
      setIsJoining(true);
      
    } catch (error) {
      console.error("Error joining session:", error);
      setStatus({ loading: false, error: error.message, hasJoined: false });
      alert(`Un error ha ocurrido: ${error.message}`);
    }
  }, []);

  return { joinParticipant, status };
};

export default useJoinParticipant;