// MyProfile.js
import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import ProfileCard from '../components/ProfileCard';
import ProfileDetails from '../components/ProfileDetails';
import { useAuth } from "../components/AuthProvider";

const MyProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { user, loading, updateProfile } = useAuth();
  const [editedProfile, setEditedProfile] = useState({
    firstName: '',
    lastName: '',
    photoURL: '',
    personalInfo: {}
  });
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (user) {
      setEditedProfile({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        photoURL: user.photoURL || '',
        personalInfo: user.personalInfo || {}
      });
    }
  }, [user]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    setIsSaving(true);
    setError(null);
    try {
      await updateProfile(editedProfile);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating profile: ", error);
      setError("Failed to update profile. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setEditedProfile({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      photoURL: user.photoURL || '',
      personalInfo: user.personalInfo || {}
    });
    setIsEditing(false);
    setError(null);
  };

  const handleProfileChange = (field, value) => {
    setEditedProfile(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handlePersonalInfoChange = (field, value) => {
    setEditedProfile(prev => ({
      ...prev,
      personalInfo: {
        ...prev.personalInfo,
        [field]: value
      }
    }));
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 1300, mx: 'auto', p: 2 }}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={10} md={4.5} lg={4}>
          <Box sx={{ 
            maxWidth: 350, 
            ml: { md: 4, lg: 6 },
            mx: { xs: 'auto', md: 'unset' }
          }}>
            <ProfileCard 
              isEditing={isEditing}
              editedProfile={editedProfile}
              handleProfileChange={handleProfileChange}
              loading={loading}
              sx={{ width: '100%' }}
            />
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
              {isEditing ? (
                <Button
                  onClick={handleSave}
                  variant="contained"
                  size="large"
                  disabled={isSaving}
                  fullWidth
                >
                  {isSaving ? "Guardando..." : "Guardar cambios"}
                </Button>
              ) : (
                <Button
                  onClick={handleEdit}
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  Editar perfil
                </Button>
              )}
              {isEditing && (
                <Button
                  onClick={handleCancel}
                  variant="outlined"
                  size="large"
                  disabled={isSaving}
                  fullWidth
                  sx={{
                    color: '#8E24AA',
                    borderColor: '#8E24AA',
                    '&:hover': {
                      borderColor: '#6A1B9A',
                      backgroundColor: 'rgba(142, 36, 170, 0.04)',
                    },
                  }}
                >
                  Cancelar
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={10} md={7} lg={7}>
          <Box sx={{ maxWidth: 700, mx: 'auto' }}>
            <ProfileDetails 
              isEditing={isEditing} 
              editedProfile={editedProfile}
              handlePersonalInfoChange={handlePersonalInfoChange}
            />
          </Box>
        </Grid>
      </Grid>
      {error && <Typography color="error" align="center" sx={{ mt: 2 }}>{error}</Typography>}
    </Box>
  );
};

export default MyProfile;