// AddEventPackModal.jsx
import React, { useState } from 'react';
import { Modal, Box, IconButton, TextField, Typography, Button, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import 'moment/locale/es';  // Import Spanish locale
import useAddEventPack from '../hooks/useAddEventPack';

const AddEventPackModal = ({ isOpen, closeModal }) => {
    // Set moment to use Spanish locale
    moment.locale('es');
    
    const { eventPackData, setEventPackData, addEventPack } = useAddEventPack();
    const [alertInfo, setAlertInfo] = useState({ show: false, message: '', severity: 'success' });

    const handleAddEventPack = async () => {
        const result = await addEventPack(eventPackData);

        if (result.success) {
            setAlertInfo({ show: true, message: 'Event pack added successfully!', severity: 'success' });
            setTimeout(() => {
                closeModal();
                setAlertInfo({ show: false, message: '', severity: 'success' });
            }, 2000);
        } else {
            setAlertInfo({ show: true, message: `Error adding event pack: ${result.error}`, severity: 'error' });
        }
    };

    const handleDateChange = (e) => {
        const selectedDate = moment(e.target.value).toDate();
        setEventPackData({
            ...eventPackData,
            date: selectedDate,
        });
    };

    const canSave = eventPackData.date;

    return (
        isOpen && (
            <Modal
                open={isOpen}
                closeAfterTransition
                slotProps={{
                    backdrop: {
                        sx: {
                            backdropFilter: 'blur(5px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10%',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#fff',
                            padding: 4,
                            borderRadius: '10px',
                            minWidth: '500px',
                        }}
                    >
                        <Box display={'flex'} justifyContent={'end'}>
                            <IconButton onClick={closeModal}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        {alertInfo.show && (
                            <Box mb={2}>
                                <Alert severity={alertInfo.severity}>
                                    {alertInfo.message}
                                </Alert>
                            </Box>
                        )}
                        <Typography mb={1}>Add Event Pack</Typography>
                        <Box mb={2}>
                            <Typography variant="body2" color="text.secondary" mb={1}>
                                This will create sessions at 16:00, 17:00, 18:00, 19:00, 20:00, and 21:00
                            </Typography>
                            <TextField
                                fullWidth
                                label="Date"
                                variant="outlined"
                                type="date"
                                value={moment(eventPackData.date).format('YYYY-MM-DD')}
                                onChange={handleDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box display={'flex'} justifyContent={'end'} p={1}>
                            <Button 
                                disabled={!canSave} 
                                variant="contained" 
                                color="primary" 
                                onClick={handleAddEventPack}
                            >
                                Add Event Pack
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        )
    );
};

export default AddEventPackModal;