import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const RequireAuth = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner or any other component
  }

  return user ? <Navigate to="/homepage" replace /> : <Outlet />;
};

export default RequireAuth;
