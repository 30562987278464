import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  StreamCall,
  StreamVideo,
  StreamVideoClient,
} from '@stream-io/video-react-sdk';
import { ref, onValue, off } from 'firebase/database';
import { realtimeDb } from '../firebase';
import Navbar from '../components/Navbar';
import MyUILayout from '../components/MyUILayout';
import useFetchUserToken from '../hooks/useFetchUserToken';
import usePresenceTracking from '../hooks/usePresenceTracking';
import PictureInPictureTip from '../components/PictureInPictureTip';

import '@stream-io/video-react-sdk/dist/css/styles.css';
import './sessionroom.css';

const apiKey = '97t74uvyrbn8';

const SessionRoom = () => {
  const timerRef = useRef(null);
  const [client, setClient] = useState(null);
  const [call, setCall] = useState(null);
  const { user, token } = useFetchUserToken();
  const navigate = useNavigate();
  const location = useLocation();
  const callRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [remainingTime, setRemainingTime] = useState(null);
  const [sessionEndTime, setSessionEndTime] = useState(null);
  const sessionEndTimeRef = useRef(null);

  const fullSessionId = location.pathname.split('/').pop();
  const roomId = fullSessionId.slice(-2);
  const sessionId = fullSessionId.slice(0, -2);
  const callId = `${sessionId}_room${roomId}`;

  usePresenceTracking(sessionId, roomId, user?.uid);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLeaveCall = useCallback(
    () => {
      if (callRef.current) {
        try {
          // First leave the call
          callRef.current.leave();
          
          // Then clean up
          if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
          }
          
          callRef.current = null;
          setCall(null);
          
          // Reset document title before navigating
          document.title = 'SessionFocus';
          
          // Finally navigate
          navigate('/');
        } catch (error) {
          console.error('Error leaving call:', error);
          // Reset title and navigate even if there's an error
          document.title = 'SessionFocus';
          navigate('/');
        }
      } else {
        document.title = 'SessionFocus';
        navigate('/');
      }
    },
    [navigate]
  );

  useEffect(() => {
    const handlePopState = () => {
      if (callRef.current) {
        try {
          callRef.current.leave();
          document.title = 'SessionFocus';
        } catch (error) {
          console.error('Error leaving call on pop state:', error);
          document.title = 'SessionFocus';
        }
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  useEffect(() => {
    const joinCall = async () => {
      if (!token || !user) return;

      try {
        const firstName = user.displayName
          ? user.displayName.split(' ')[0]
          : user.uid;

        const streamUser = {
          id: user.uid,
          name: firstName,
          image: user.photoURL ||
            `https://getstream.io/random_svg/?id=${user.uid}&name=${firstName}`,
        };

        const videoClient = new StreamVideoClient({
          apiKey,
          user: streamUser,
          token,
        });

        setClient(videoClient);

        const videoCall = videoClient.call('default', callId);
        await videoCall.join({ create: true });
        setCall(videoCall);
        callRef.current = videoCall;

      } catch (error) {
        console.error('Error joining call:', error);
      }
    };

    if (token && user) {
      joinCall();
    }

    return () => {
      if (callRef.current) {
        try {
          callRef.current.leave();
          callRef.current = null;
          document.title = 'SessionFocus';
        } catch (error) {
          console.error('Error leaving call during cleanup:', error);
          document.title = 'SessionFocus';
        }
      }
    };
  }, [token, user, callId]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      if (callRef.current) {
        try {
          callRef.current.leave();
          document.title = 'SessionFocus';
        } catch (error) {
          console.error('Error leaving call on unload:', error);
          document.title = 'SessionFocus';
        }
      }
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  useEffect(() => {
    const sessionRef = ref(realtimeDb, `sessions/${sessionId}`);

    const handleSessionData = (snapshot) => {
      const sessionData = snapshot.val();
      if (sessionData) {
        const startTime = new Date(sessionData.startDate);
        const endTime = new Date(startTime.getTime() + 50 * 60 * 1000);
        setSessionEndTime(endTime);
        sessionEndTimeRef.current = endTime;
      }
    };

    onValue(sessionRef, handleSessionData);
    return () => {
      off(sessionRef, 'value', handleSessionData);
      document.title = 'SessionFocus';
    };
  }, [sessionId]);

  useEffect(() => {
    if (!sessionEndTime) return;

    const updateRemainingTime = () => {
      const now = new Date();
      const difference = sessionEndTime.getTime() - now.getTime();

      if (difference <= 0) {
        setRemainingTime('00:00');
        document.title = 'Sesión terminada';
        clearInterval(timerRef.current);
        return;
      }

      const totalSeconds = Math.floor(difference / 1000);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;

      const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
      setRemainingTime(formattedTime);
      document.title = `${formattedTime} - SessionFocus`;
    };

    updateRemainingTime();
    timerRef.current = setInterval(updateRemainingTime, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      document.title = 'SessionFocus';
    };
  }, [sessionEndTime]);

  if (!token || !call) {
    return <div>Loading...</div>;
  }

  return (
    <div className="session-room-container">
      <Navbar 
        sessionId={sessionId} 
        roomId={roomId} 
        className={isMobile ? 'mobile-navbar' : ''}
      />
      <div className="session-room-content">
        {!isMobile && (
          <div className="pip-tip-container">
            <PictureInPictureTip />
          </div>
        )}
        <div className={`video-section ${isMobile ? 'mobile' : ''}`}>
          <StreamVideo client={client}>
            <StreamCall call={call}>
              <MyUILayout
                handleLeaveCall={handleLeaveCall}
                sessionId={sessionId}
                roomId={roomId}
                user={user}
                timerRef={timerRef}
                isMobile={isMobile}
              />
            </StreamCall>
          </StreamVideo>
        </div>
      </div>
    </div>
  );
};

export default SessionRoom;