import React from 'react';
import {
  SpeakingWhileMutedNotification,
  ToggleAudioPublishingButton,
  ToggleVideoPublishingButton,
  ScreenShareButton,
  ReactionsButton,
  CancelCallButton,
} from '@stream-io/video-react-sdk';

const CallControls = ({ handleLeaveCall }) => {
  return (
    <div className="call-controls-container">
      <div className="str-video__call-controls">
        <SpeakingWhileMutedNotification>
          <ToggleAudioPublishingButton />
        </SpeakingWhileMutedNotification>
        <ToggleVideoPublishingButton />
        <ScreenShareButton />
        <ReactionsButton />
        <CancelCallButton onClick={handleLeaveCall} />
      </div>
    </div>
  );
};

export default CallControls;