import { useMemo } from 'react';

const useDetermineTargetRoom = (distribution, currentRoomId) => {
  const targetRoom = useMemo(() => {
    // Check if the user is completely alone in the distribution
    if (distribution.length === 1 && 
        distribution[0].count === 1 && 
        distribution[0].roomId === currentRoomId) {
      return "alone";
    }

    // Filter rooms with 2 or more people
    const roomsWithTwoOrMore = distribution.filter(room => room.count >= 2);
    // Filter rooms with exactly 1 person
    const onePersonRooms = distribution.filter(room => room.count === 1);

    // Priority 1: If there are rooms with 2 or more people, return the one with the lowest occupancy
    if (roomsWithTwoOrMore.length > 0) {
      const lowestOccupancyRoom = roomsWithTwoOrMore.reduce((min, room) => 
        room.count < min.count ? room : min
      );
      return lowestOccupancyRoom.roomId;
    } 
    // Priority 2: If there are at least 2 rooms with 1 person
    else if (onePersonRooms.length > 1) {
      // Find the room with the lowest number in its ID
      const lowestRoom = onePersonRooms.reduce((min, room) => 
        parseInt(room.roomId.match(/\d+$/)[0]) < parseInt(min.roomId.match(/\d+$/)[0]) ? room : min
      );
      
      // If the user is already in the lowest-numbered room, return "stay"
      if (lowestRoom.roomId === currentRoomId) {
        return "stay";
      }
      
      // Otherwise, return the lowest numbered room
      return lowestRoom.roomId;
    }

    // If no suitable room found, return null
    return null;
  }, [distribution, currentRoomId]);

  return targetRoom;
};

export default useDetermineTargetRoom;