import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactSection from "../components/ContactSection";

function ContactPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        background: "linear-gradient(to bottom, #ffffff, #f5f9fc)",
      }}
    >
      <Navbar />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            paddingTop: "40px",
          }}
        >
          <ContactSection
            bgColor="default"
            size="medium"
            bgImage=""
            bgImageOpacity={1}
            title="Contáctanos"
            subtitle="Estamos abiertos a recibir sugerencias, quejas, reclamos, o cualquier información que quieras compartir con nosotros."
            buttonText="Enviar mensaje"
            buttonColor="primary"
            showNameField={true}
          />
        </Box>
      </Box>
      <Box>
        <Divider />
        <Footer />
      </Box>
    </Box>
  );
}

export default ContactPage;