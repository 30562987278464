import React from "react";
import { Typography, Link as MuiLink } from "@mui/material";

function LegalPrivacy({ domain }) {
  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Política de Privacidad
      </Typography>
      <div style={{ marginBottom: '20px' }}></div>
      <Typography paragraph>
        <strong>SessionFocus</strong> se compromete a proteger la privacidad de sus usuarios. Esta política de privacidad describe cómo recopilamos, utilizamos, divulgamos y protegemos su información personal cuando utiliza nuestra aplicación.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Información que Recopilamos
      </Typography>
      <Typography paragraph>
        Recopilamos información personal que nos proporciona directamente, como su nombre, dirección de correo electrónico y cualquier otra información que ingrese al crear una cuenta o utilizar nuestra aplicación. También recopilamos información automáticamente sobre su uso de nuestra aplicación.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Uso de la Información
      </Typography>
      <Typography paragraph>
        Utilizamos la información que recopilamos para:
        <ul>
          <li>Proveer y mejorar nuestros servicios.</li>
          <li>Personalizar su experiencia en la aplicación.</li>
          <li>Comunicarnos con usted, incluida la respuesta a sus preguntas y comentarios.</li>
          <li>Monitorizar y analizar tendencias, uso y actividades en relación con nuestra aplicación.</li>
        </ul>
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Divulgación de Información
      </Typography>
      <Typography paragraph>
        No compartimos su información personal con terceros, excepto en las siguientes circunstancias:
        <ul>
          <li>Con su consentimiento.</li>
          <li>Para cumplir con la ley, una orden judicial o un proceso legal.</li>
          <li>Para proteger nuestros derechos, propiedad o seguridad, y los de nuestros usuarios u otros.</li>
        </ul>
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Seguridad de la Información
      </Typography>
      <Typography paragraph>
        Tomamos medidas razonables para proteger la información personal contra pérdida, robo, uso indebido, acceso no autorizado, divulgación, alteración y destrucción.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Retención de Datos
      </Typography>
      <Typography paragraph>
        Retenemos su información personal mientras su cuenta esté activa o según sea necesario para proporcionarle nuestros servicios. Podemos retener y utilizar su información según sea necesario para cumplir con nuestras obligaciones legales, resolver disputas y hacer cumplir nuestros acuerdos.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Sus Derechos
      </Typography>
      <Typography paragraph>
        Usted tiene derecho a acceder, corregir, actualizar o solicitar la eliminación de su información personal. Para ejercer estos derechos, por favor, póngase en contacto con nosotros.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Cambios en la Política de Privacidad
      </Typography>
      <Typography paragraph>
        Podemos actualizar esta Política de Privacidad de vez en cuando. Le notificaremos sobre cualquier cambio mediante la publicación de la nueva política en nuestra aplicación. Se recomienda revisar esta política periódicamente para estar informado sobre cómo protegemos su información.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Contacto
      </Typography>
      <Typography paragraph>
        Si tiene preguntas o inquietudes sobre esta Política de Privacidad, por favor contáctenos a <MuiLink href="mailto:contacto@sessionfocus.com">contacto@sessionfocus.com</MuiLink>.
      </Typography>
    </>
  );
}

export default LegalPrivacy;
