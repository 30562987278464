import React, { useState } from 'react';
import { Modal, Box, IconButton, TextField, Typography, Button, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import useAddEvent from '../hooks/useAddEvent';
import toast from 'react-hot-toast';

const AddEventModal = ({ isOpen, closeModal }) => {
    const { eventData, setEventData, addEvent } = useAddEvent();
    const [alertInfo, setAlertInfo] = useState({ show: false, message: '', severity: 'success' });

    const handleAddEvent = async () => {
        if (!eventData.title) {
            toast.error('Error: Title is required for an event.');
            setAlertInfo({ show: true, message: 'Error: Title is required for an event.', severity: 'error' });
            return;
        }

        const result = await addEvent(eventData);

        if (result.success) {
            setAlertInfo({ show: true, message: 'Event added successfully!', severity: 'success' });
            setTimeout(() => {
                closeModal();
                setAlertInfo({ show: false, message: '', severity: 'success' });
            }, 2000);
        } else {
            setAlertInfo({ show: true, message: `Error adding event: ${result.error}`, severity: 'error' });
        }
    };

    const handleDateTimeChange = (e) => {
        const selectedDateTime = new Date(e.target.value);
        setEventData({
            ...eventData,
            startDate: selectedDateTime,
            endDate: new Date(selectedDateTime.getTime() + 50 * 60000), // Set end time to 50 minutes later
        });
    };

    const canSave = [eventData.title, eventData.startDate, eventData.endDate].every(Boolean);

    return (
        isOpen && (
            <Modal
                open={isOpen}
                closeAfterTransition
                slotProps={{
                    backdrop: {
                        sx: {
                            backdropFilter: 'blur(5px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10%',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#fff',
                            padding: 4,
                            borderRadius: '10px',
                            minWidth: '500px',
                        }}
                    >
                        <Box display={'flex'} justifyContent={'end'}>
                            <IconButton onClick={closeModal}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        {alertInfo.show && (
                            <Box mb={2}>
                                <Alert severity={alertInfo.severity}>
                                    {alertInfo.message}
                                </Alert>
                            </Box>
                        )}
                        <Typography mb={1}>Add Event</Typography>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                label="Title"
                                variant="outlined"
                                type="text"
                                value={eventData.title}
                                onChange={(e) =>
                                    setEventData({ ...eventData, title: e.target.value })
                                }
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                label="Start Time"
                                variant="outlined"
                                type="datetime-local"
                                value={moment(eventData.startDate).format('YYYY-MM-DDTHH:mm')}
                                onChange={handleDateTimeChange}
                                inputProps={{
                                    step: 60 // Allow minute-level granularity (60 seconds step)
                                }}
                            />
                        </Box>
                        <Box display={'flex'} justifyContent={'end'} p={1}>
                            <Button 
                                disabled={!canSave} 
                                variant="contained" 
                                color="primary" 
                                onClick={handleAddEvent}
                            >
                                Add Event
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        )
    );
};

export default AddEventModal;
