const contact = {
  submit: async (data) => {
    const response = await fetch("https://us-central1-sessionfocus-ea1ce.cloudfunctions.net/function-1-test-sessionfocus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to send message");
    }

    return response.json();
  },
};

export default contact;
