// RedistributionCountdown.js

import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, useTheme } from '@mui/material';
import { ref, onValue, off } from 'firebase/database';
import { realtimeDb } from '../firebase';

const RedistributionCountdown = ({ sessionId, roomId }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [redistributionTime, setRedistributionTime] = useState(null);
  const [redistributionOccurred, setRedistributionOccurred] = useState(false);
  const [isUserAlone, setIsUserAlone] = useState(null);
  const theme = useTheme();

  // Effect to determine if the user is alone in the room
  useEffect(() => {
    const usersPath = `sessions/${sessionId}/rooms/${roomId}/users`;
    const usersRef = ref(realtimeDb, usersPath);

    const handleUsersChange = (snapshot) => {
      const usersData = snapshot.val() || {};
      const activeUsers = Object.values(usersData).filter(user => user.presence === true);
      setIsUserAlone(activeUsers.length === 1);
    };

    onValue(usersRef, handleUsersChange);

    return () => {
      off(usersRef, 'value', handleUsersChange);
    };
  }, [sessionId, roomId]);

  // Fetch session data from Firebase
  useEffect(() => {
    const sessionRef = ref(realtimeDb, `sessions/${sessionId}`);

    const handleSessionData = (snapshot) => {
      const sessionData = snapshot.val();
      if (sessionData) {
        const startTime = new Date(sessionData.startDate);
        // Redistribution time is 90 seconds after session start time
        const redistributionTime = new Date(startTime.getTime() + 90 * 1000);
        setRedistributionTime(redistributionTime);
      }
    };

    onValue(sessionRef, handleSessionData);

    return () => {
      off(sessionRef, 'value', handleSessionData);
    };
  }, [sessionId]);

  // Timer to update time left
  useEffect(() => {
    if (!redistributionTime || isUserAlone !== true) {
      return;
    }

    // Set up timer interval
    const timer = setInterval(() => {
      const now = new Date();
      const difference = redistributionTime.getTime() - now.getTime();

      if (difference <= 0) {
        setTimeLeft(null);
        setRedistributionOccurred(true);
        clearInterval(timer);
        return;
      }

      // Calculate and set remaining time
      const minutes = Math.floor(difference / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      setTimeLeft(formattedTime);

    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [redistributionTime, isUserAlone]);

  // Handle initial loading state
  if (isUserAlone === null) {
    return null; // or a loading indicator
  }

  // Don't render anything if the user is not alone
  if (!isUserAlone) {
    return null;
  }

  // Don't render anything after redistribution has occurred
  if (redistributionOccurred || timeLeft === null) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '6px 16px',
          height: '36px',
          backgroundColor: theme.palette.info.main,
          color: theme.palette.info.contrastText,
          borderRadius: '10px',
          cursor: 'default',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: theme.palette.info.dark,
          },
        }}
      >
        <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
          Redistribución en:
        </Typography>
        <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', letterSpacing: 1 }}>
          {timeLeft}
        </Typography>
      </Paper>
    </Box>
  );
};

export default RedistributionCountdown;
