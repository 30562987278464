import { useCallback } from 'react';
import { getFirestore, doc, arrayUnion, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const useAddJoinedSessions = () => {
  const addJoinedSession = useCallback(async (sessionId) => {
    const auth = getAuth();
    const db = getFirestore();
    const user = auth.currentUser;

    if (!user) {
      throw new Error("User not authenticated");
    }

    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        joinedSessions: arrayUnion(sessionId)
      });
      console.log(`Session ${sessionId} added to user's joined sessions`);
    } catch (error) {
      console.error("Error adding joined session:", error);
      throw error;
    }
  }, []);

  return addJoinedSession;
};

export default useAddJoinedSessions;