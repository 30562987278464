import { getAuth, createUserWithEmailAndPassword, updateProfile, fetchSignInMethodsForEmail } from "firebase/auth";
import { app } from '../firebase'; // adjust this import based on your firebase.js location

const handleSignUpWithEmail = async (event) => {
  event.preventDefault();
  const data = new FormData(event.currentTarget);
  const email = data.get('email');
  const password = data.get('password');
  const firstName = data.get('firstName');
  const lastName = data.get('lastName');
  const username = `${firstName} ${lastName}`;

  const auth = getAuth(app);
  try {
    // Check if email already exists
    const methods = await fetchSignInMethodsForEmail(auth, email);
    if (methods.length > 0) {
      // Email already exists
      throw new Error('auth/email-already-in-use');
    }

    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;


    // Update the user's profile with their display name
    await updateProfile(user, {
      displayName: username,
      photoURL: user.photoURL
    });

    // Refresh user data in the AuthProvider
    const updatedUser = await auth.currentUser;
    await auth.updateCurrentUser(updatedUser);
    
    return user;
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      console.error('Email already in use');
      throw error;
    } else {
      console.error('Error during email sign up:', error.code, error.message);
      throw error;
    }
  }
};

export default handleSignUpWithEmail;

