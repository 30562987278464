// Navbar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import theme from '../theme';
import logoSF2 from '../assets/images/logoSF2.png';
import circularlogo from '../assets/images/circularlogo.png';
import { useAuth } from './AuthProvider';
import handleLogout from './handleLogout';
import { useNavigate } from 'react-router-dom';
import CountdownTimer from './CountdownTimer';
import RedistributionCountdown from './RedistributionCountdown';
import MobileDrawer from './MobileDrawer';

const pages = [
  { label: 'Regístrate', path: '/signup' },
  { label: 'Iniciar sesión', path: '/login' }
];

const Navbar = ({ sessionId, roomId }) => {
  const { user } = useAuth();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isSessionRoom = location.pathname.startsWith('/sessionroom/');

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoutClick = () => {
    handleLogout(navigate);
    handleCloseUserMenu();
  };

  const toggleMobileDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const userMenuItems = [
    { label: 'Mi perfil', path: '/profilepage' },
    { label: 'Calendario', path: '/calendar' },
    { label: 'Cerrar sesión', path: '/' }
  ];

  return (
    <ThemeProvider theme={theme}>
      <AppBar 
        position="absolute" 
        sx={{ 
          backgroundColor: theme.palette.navbar.main,
          height: 'auto',
          minHeight: isSessionRoom ? { xs: '40px', md: '64px' } : '64px',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              minHeight: isSessionRoom ? { xs: '40px', md: '64px' } : '64px',
              py: 0.25,
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              {/* Mobile Menu Icon - Hidden in session room */}
              {!isSessionRoom && (
                <IconButton
                  sx={{
                    display: { xs: 'flex', md: 'none' },
                    mr: 1,
                    color: 'white'
                  }}
                  onClick={toggleMobileDrawer}
                >
                  <MenuIcon />
                </IconButton>
              )}

              {isSessionRoom ? (
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  // Keep logo visible but smaller on mobile in session room
                  '@media (max-width: 768px)': {
                    transform: 'scale(0.8)',
                    marginLeft: '-10px'
                  }
                }}>
                  <img src={logoSF2} alt="Logo" style={{ height: '30px', maxHeight: '100%' }} />
                </Box>
              ) : (
                <Link to="/" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <img src={logoSF2} alt="Logo" style={{ height: '30px', maxHeight: '100%' }} />
                </Link>
              )}
            </Box>

            {isSessionRoom ? (
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: { xs: 1, md: 2 },
                // Adjust timer layout on mobile
                '@media (max-width: 768px)': {
                  transform: 'scale(0.85)',
                  marginRight: '-10px'
                }
              }}>
                <CountdownTimer sessionId={sessionId} />
                <RedistributionCountdown sessionId={sessionId} roomId={roomId} />
              </Box>
            ) : (
              <>
                <Box sx={{ flexGrow: 1 }} />
                {/* Desktop Buttons */}
                <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                  {(user ? [] : pages).map((page) => (
                    <Button
                      key={page.label}
                      component={Link}
                      to={page.path}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.navbar.button,
                        color: theme.palette.navbar.buttonText,
                        '&:hover': {
                          backgroundColor: theme.palette.navbar.buttonHover,
                        },
                        mx: 0.5,
                        textTransform: 'none',
                        fontWeight: 'bold',
                      }}
                    >
                      {page.label}
                    </Button>
                  ))}
                  {user && (
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{
                        ml: 1,
                        width: 40,
                        height: 40,
                        padding: 0,
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    >
                      <img
                        src={user.photoURL || circularlogo}
                        alt="User"
                        style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                      />
                    </IconButton>
                  )}
                </Box>

                {/* User Profile Menu */}
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar-user"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {userMenuItems.map((item) => (
                    <MenuItem
                      key={item.label}
                      onClick={
                        item.label === 'Cerrar sesión'
                          ? handleLogoutClick
                          : handleCloseUserMenu
                      }
                    >
                      <Typography textAlign="center">
                        <Link
                          to={item.path}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {item.label}
                        </Link>
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      {/* Mobile Drawer - Only render if not in session room */}
      {!isSessionRoom && (
        <MobileDrawer
          open={mobileDrawerOpen}
          onClose={() => setMobileDrawerOpen(false)}
          user={user}
          onLogout={handleLogoutClick}
        />
      )}
    </ThemeProvider>
  );
};

export default Navbar;