import React from "react";
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from "react-router-dom";
import Box from '@mui/material/Box';
import NotFoundPage from "./pages/404";
import SuccessPage from "./pages/PaymentSuccessPage";
import FailurePage from "./pages/PaymentFailurePage";
import SessionRoom from "./pages/SessionRoom";
import ContactPage from "./pages/contact";
import AyudaPage from "./pages/AyudaPage";
import LegalPage from "./pages/legal";
import SignUpPage from "./pages/SignUpPage";
import ProfilePage from "./pages/ProfilePage";
import FAQPage from "./pages/FAQPage";
import LogInPage from "./pages/LogInPage";
import CalendarPage from "./pages/CalendarPage";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthProvider from "./components/AuthProvider";
import RequireAuth from "./components/RequireAuth";
import PricingPage from "./pages/PricingPage";
import { GlobalStyles, ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./theme";
import HomePage from "./pages/HomePage";
import SettingsPage from "./pages/SettingsPage";
import WorkInProgressFile from "./pages/WorkInProgressFile"; // Add this import

const GlobalCss = () => (
  <GlobalStyles styles={{ body: { margin: 0 } }} />
);

const Layout = () => (
  <Box sx={{minHeight: '100vh'}}>
    <GlobalCss />
    <Outlet />
  </Box>
);

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        element: <RequireAuth />,
        children: [
          { path: "/", element: <WorkInProgressFile /> }, // Changed from LandingPage to WorkInProgressFile
        ],
      },
      { path: "/contact", element: <ContactPage /> },
      { path: "/signup", element: <SignUpPage /> },
      { path: "/login", element: <LogInPage /> },
      { path: "/pricing", element: <ProtectedRoute><PricingPage /></ProtectedRoute> },
      { path: "/homepage", element: <ProtectedRoute><HomePage /></ProtectedRoute> },
      { path: "/successpage", element: <ProtectedRoute><SuccessPage /></ProtectedRoute> },
      { path: "/failurepage", element: <ProtectedRoute><FailurePage /></ProtectedRoute> },
      { path: "/sessionroom/:sessionId", element: <ProtectedRoute><SessionRoom /></ProtectedRoute> },
      { path: "/profilepage", element: <ProtectedRoute><ProfilePage /></ProtectedRoute> },
      { path: "/settings", element: <ProtectedRoute><SettingsPage /></ProtectedRoute> },
      { path: "/legal", element: <Navigate to="/legal/terms-of-service" replace /> },
      { path: "/legal/:section", element: <LegalPage /> },
      { path: "/FAQ", element: <FAQPage /> },
      { path: "/ayuda", element: <AyudaPage /> },
      { path: "/calendar", element: <ProtectedRoute><CalendarPage /></ProtectedRoute> },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
  { path: "/404", element: <NotFoundPage /> }
]);

export default function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </AuthProvider>
  );
}