import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { emphasize } from "@mui/material/styles";
import { capitalize } from "@mui/material/utils";
import BackgroundImage from "./BackgroundImage";

const Root = styled(Box)(({ theme }) => {
  const createColorClass = (name, value) => ({
    backgroundColor: value,
    color: theme.palette.getContrastText(value),
    [`& + &`]: {
      borderTop: `1px solid ${emphasize(value, 0.09)}`,
    },
  });

  return {
    position: "relative",
    "& > .MuiContainer-root": {
      position: "relative",
    },
    ...{
      colorDefault: createColorClass("default", theme.palette.background.default),
      colorLight: createColorClass("light", emphasize(theme.palette.background.default, 0.03)),
      colorPrimary: createColorClass("primary", theme.palette.primary.main),
      colorSecondary: createColorClass("secondary", theme.palette.secondary.main),
    },
    colorInherit: {
      color: "inherit",
    },
    colorTransparent: {
      backgroundColor: "transparent",
      color: "inherit",
    },
  };
});

function Section(props) {
  const {
    bgColor = "default",
    bgImage,
    bgImageOpacity,
    size = "normal",
    className,
    children,
    ...otherProps
  } = props;

  // Get MUI responsive size object based
  // on size prop (normal, medium, large, auto)
  const verticalPadding = {
    normal: { xs: 6 },
    medium: { xs: 6, sm: 10 },
    large: { xs: 6, sm: 20 },
    auto: 0,
  }[size];

  return (
    <Root
      component="section"
      py={verticalPadding}
      className={`color${capitalize(bgColor)}${className ? ` ${className}` : ""}`}
      {...otherProps}
    >
      {bgImage && <BackgroundImage image={bgImage} opacity={bgImageOpacity} />}
      {children}
    </Root>
  );
}

export default Section;