// hooks/useLateRoomSituation.js

import { useState, useEffect } from 'react';
import { ref, onValue, off } from 'firebase/database';
import { realtimeDb } from '../firebase'; // Adjust the path as needed

const useLateRoomSituation = (sessionID) => {
  const [room, setRoom] = useState(null);

  // Helper function to extract room number from room string
  const extractRoomNumber = (roomString) => {
    const match = roomString.match(/\d+/);
    return match ? match[0].padStart(2, '0') : null;
  };

  useEffect(() => {
    if (!sessionID) {
      setRoom(null);
      return;
    }

    const roomsRef = ref(realtimeDb, `sessions/${sessionID}/rooms`);

    const handleValueChange = (snapshot) => {
      const roomsData = snapshot.val();
      console.log('Fetched roomsData:', roomsData); // Debugging log

      if (roomsData) {
        // Compute roomSituation
        const roomSituation = Object.entries(roomsData).reduce((acc, [roomNumber, roomData]) => {
          console.log(`Processing ${roomNumber}:`, roomData); // Debugging log

          // Ensure roomData.users exists and is an object
          if (roomData.users && typeof roomData.users === 'object') {
            const activeUsers = Object.values(roomData.users).filter(user => {
              console.log(`User presence for ${roomNumber}:`, user.presence); // Debugging log
              return user.presence === true;
            }).length;
            acc[roomNumber] = activeUsers;
          } else {
            acc[roomNumber] = 0;
          }

          return acc;
        }, {});

        console.log('Computed roomSituation:', roomSituation); // Debugging log

        // Step 1: Try to find rooms with 2 or more active users
        const roomsWithTwoOrMore = Object.keys(roomSituation).filter(roomKey => roomSituation[roomKey] >= 2);

        console.log('Rooms with 2 or more active users:', roomsWithTwoOrMore); // Debugging log

        if (roomsWithTwoOrMore.length > 0) {
          // Randomly assign to one of these rooms
          const randomIndex = Math.floor(Math.random() * roomsWithTwoOrMore.length);
          const roomToAssign = roomsWithTwoOrMore[randomIndex];
          console.log(`Randomly assigned to room: ${roomToAssign}`); // Debugging log

          const roomNumber = extractRoomNumber(roomToAssign);
          setRoom(roomNumber);
        } else {
          // Step 2: Try to find rooms with exactly 1 active user
          const roomsWithOneUser = Object.keys(roomSituation).filter(roomKey => roomSituation[roomKey] === 1);

          console.log('Rooms with 1 active user:', roomsWithOneUser); // Debugging log

          if (roomsWithOneUser.length > 0) {
            // Randomly assign to one of these rooms
            const randomIndex = Math.floor(Math.random() * roomsWithOneUser.length);
            const roomToAssign = roomsWithOneUser[randomIndex];
            console.log(`Randomly assigned to room with 1 user: ${roomToAssign}`); // Debugging log

            const roomNumber = extractRoomNumber(roomToAssign);
            setRoom(roomNumber);
          } else {
            // Step 3: No suitable rooms found, assign to a new room
            const sortedRooms = Object.keys(roomSituation).sort((a, b) => {
              const numA = parseInt(a.replace(/\D/g, ''), 10);
              const numB = parseInt(b.replace(/\D/g, ''), 10);
              return numA - numB;
            });

            console.log('Sorted rooms:', sortedRooms); // Debugging log

            let nextRoomNumber = 1;
            if (sortedRooms.length > 0) {
              const lastRoomKey = sortedRooms[sortedRooms.length - 1];
              const lastNumber = parseInt(lastRoomKey.replace(/\D/g, ''), 10);
              nextRoomNumber = lastNumber + 1;
            }

            const nextRoomKey = `room${String(nextRoomNumber).padStart(2, '0')}`;
            const recommendedRoomNumber = extractRoomNumber(nextRoomKey);
            setRoom(recommendedRoomNumber);
            console.log(`No rooms available. Assigned to new room: ${recommendedRoomNumber}`); // Debugging log
          }
        }
      } else {
        // No rooms data found; assign to the first room
        setRoom('01');
        console.log('No rooms data found. Assigned to room: 01'); // Debugging log
      }
    };

    onValue(roomsRef, handleValueChange);

    // Cleanup function to remove the listener
    return () => {
      off(roomsRef, 'value', handleValueChange);
    };
  }, [sessionID]);

  return room;
};

export default useLateRoomSituation;
