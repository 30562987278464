import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';

const useUserRegisteredSessions = () => {
  const [userRegisteredSessions, setUserRegisteredSessions] = useState([]);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    let unsubscribe;

    if (currentUser) {
      const userDocRef = doc(db, 'users', currentUser.uid);

      unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const joinedSessions = userData.joinedSessions || [];
          
          const sessions = joinedSessions.map((sessionId) => ({
            sessionId: sessionId,
          }));
          
          setUserRegisteredSessions(sessions);
        } else {
          setUserRegisteredSessions([]);
        }
      }, (error) => {
        console.error('Error in onSnapshot:', error);
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser]);

  return userRegisteredSessions;
};

export default useUserRegisteredSessions;