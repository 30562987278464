import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckIcon from "@mui/icons-material/Check";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import { styled, alpha } from "@mui/material/styles";
import { useAuth } from "../components/AuthProvider";
import { getFunctions, httpsCallable } from "firebase/functions";

const StyledCard = styled(Card)(({ theme, isPremium }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  position: "relative",
  overflow: "visible",
  backgroundColor: '#f0f0f0',
  borderRadius: 10,
  border: '1px solid #e0e0e0',
  boxShadow: 'none',
  ...(isPremium && {
    borderColor: '#0050ef',
    boxShadow: '0 0 24px #b0c4de',
    backgroundColor: alpha('#0050ef', 0.1),
  }),
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  padding: theme.spacing(3),
}));

const PriceBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "baseline",
  flexWrap: "wrap",
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: 2,
  paddingBottom: 2,
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 34,
  color: theme.palette.success.main,
}));

const UnderlinedTypography = styled(Typography)(({ theme }) => ({
  textDecoration: 'underline',
  textUnderlineOffset: '5px',
  textDecorationThickness: '2px',
  marginBottom: theme.spacing(2),
}));

const StrikethroughTypography = styled(Typography)(({ theme }) => ({
  textDecoration: 'line-through',
  color: theme.palette.error.main,
  marginRight: theme.spacing(1),
}));

const StickerImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: -20,
  right: 10,
  width: 160,
  height: 160,
  zIndex: 1,
}));

function PricingSection(props) {
  const { user } = useAuth();
  const functions = getFunctions();

  const handleSubscribe = async (planId) => {
    if (planId === "premium") {
      try {
        const createSubscription = httpsCallable(functions, 'createSubscription');
        const result = await createSubscription();
        
        if (result.data.success) {
          console.log("Subscription created successfully:", result.data.data);
          alert("Subscription created successfully! Check the console for details.");
        } else {
          console.error("Failed to create subscription");
          alert("Failed to create subscription. Please try again.");
        }
      } catch (error) {
        console.error("Error creating subscription:", error);
        alert("An error occurred while creating the subscription. Please try again.");
      }
    } else {
      console.log("Free plan selected");
      alert("Free plan selected!");
    }
  };

  const plans = [
    {
      id: "free",
      name: "Plan Gratuito",
      price: "",
      perks: [
        "Tres sesiones por semana.",
      ],
    },
    {
      id: "premium",
      name: "Plan Premium",
      price: "4999",
      originalPrice: "9999",
      perks: [
        "Sesiones infinitas por semana.",
        "Acceso a futuras funcionalidades de SessionFocus como estadísticas de uso, medallas y sesiones grupales"
      ],
    },
  ];

  return (
    <Box sx={{ bgcolor: props.bgColor, py: 8 }}>
      <Container>
        <Typography variant="h3" component="h2" align="center" gutterBottom>
          {props.title}
        </Typography>
        <Typography variant="subtitle1" align="center" paragraph sx={{ mb: 6 }}>
          {props.subtitle}
        </Typography>
        <Grid container justifyContent="center" spacing={4}>
          {plans.map((plan, index) => (
            <Grid item xs={12} md={6} key={index}>
              <StyledCard isPremium={plan.id === "premium"}>
                {plan.id === "premium" && props.stickerSrc && (
                  <StickerImage src={props.stickerSrc} alt="50% off" />
                )}
                <StyledCardContent>
                  <UnderlinedTypography variant="h4" component="h3">
                    {plan.name}
                  </UnderlinedTypography>
                  <PriceBox mt={1}>
                    {plan.originalPrice && (
                      <StrikethroughTypography variant="h5">
                        ${plan.originalPrice}
                      </StrikethroughTypography>
                    )}
                    {plan.price && (
                      <Typography variant="h3">
                        ${plan.price}
                      </Typography>
                    )}
                    {plan.id === "premium" && (
                      <Typography variant="h6" color="text.secondary">
                        /mes
                      </Typography>
                    )}
                  </PriceBox>

                  {plan.description && (
                    <Box mt={2}>
                      <Typography color="text.secondary">
                        {plan.description}
                      </Typography>
                    </Box>
                  )}

                  {plan.perks && (
                    <List aria-label="perks" sx={{ mt: 1 }}>
                      {plan.perks.map((perk, index) => (
                        <StyledListItem disableGutters key={index}>
                          <StyledListItemIcon>
                            <CheckIcon />
                          </StyledListItemIcon>
                          <ListItemText primary={perk} />
                        </StyledListItem>
                      ))}
                    </List>
                  )}

                  {plan.id === "premium" && (
                    <Box mt="auto" pt={3}>
                      <Button
                        onClick={() => {
                          window.location.href = 'https://app.reveniu.com/checkout-custom-link/hYAJH8HIlYoMbkK7hOHyn3lOQXzoumsu';
                        }}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                      >
                        Elegir
                      </Button>
                    </Box>
                  )}
                </StyledCardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
        <Typography
          variant="body2"
          align="center"
          sx={{ mt: 4, color: 'text.secondary' }}
        >
          Si tienes dudas o consultas con respecto a tu suscripción, no dudes en contactarte a <Box component="span" fontWeight="bold">contacto@sessionfocus.com</Box>
        </Typography>
      </Container>
    </Box>
  );
}

export default PricingSection;

