import React from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Navbar from "../components/Navbar";
import Typography from '@mui/material/Typography';

function NotFoundPage() {
  return (
    <>
      <Navbar />
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center', 
          minHeight: '100vh', 
          paddingTop: '40px', 
          paddingBottom: '32px', 
          background: 'linear-gradient(to bottom, #ffffff, #f5f9fc)',
          textAlign: 'center'
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
          Disculpa, no podemos encontrar esta página
        </Typography>
        <Typography variant="subtitle1">
          Puedes volver al <Link to="/">inicio</Link> desde aquí.
        </Typography>
      </Box>
    </>
  );
}

export default NotFoundPage;
