import { useState } from "react";
import { collection, setDoc, doc, Timestamp } from "firebase/firestore";
import { db, realtimeDb } from '../firebase'; // Import both Firestore and Realtime Database
import { ref, set } from 'firebase/database'; // Import functions for Realtime Database
import toast from "react-hot-toast";

const useAddEvent = () => {
  const [eventData, setEventData] = useState({
    title: "",
    startDate: new Date(),
    endDate: new Date(new Date().getTime() + 50 * 60000),
  });

  const addEvent = async (eventData) => {
    try {
      console.log("Adding event with data:", eventData);

      // Generate a custom ID
      const customId = doc(collection(db, "sessions")).id;

      // Add event to Firestore
      const eventDocRef = doc(db, "sessions", customId);
      await setDoc(eventDocRef, {
        title: eventData.title,
        startDate: Timestamp.fromDate(eventData.startDate),
        endDate: Timestamp.fromDate(eventData.endDate),
      });

      console.log("Document written with ID: ", customId);

      // Add event to Realtime Database using the same ID
      const eventRealtimeDbRef = ref(realtimeDb, `sessions/${customId}`);
      await set(eventRealtimeDbRef, {
        title: eventData.title,
        startDate: eventData.startDate.toISOString(), // Save as ISO string for Realtime DB
        endDate: eventData.endDate.toISOString(),
      });

      console.log("Event added to Realtime Database with ID: ", customId);

      setEventData({
        title: "",
        startDate: new Date(),
        endDate: new Date(new Date().getTime() + 50 * 60000),
      });

      toast.success("Event added successfully!");
      return { success: true, id: customId };
    } catch (error) {
      console.error("Error adding event:", error);
      toast.error(`Error adding event: ${error.message}`);
      return { success: false, error: error.message };
    }
  };

  return {
    eventData,
    setEventData,
    addEvent,
  };
};

export default useAddEvent;