import React from 'react';
import { ListItem, ListItemText, ListItemIcon, Paper, IconButton } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useJoinParticipant } from '../hooks/useJoinParticipant';
import { getAuth } from 'firebase/auth';
import theme from '../theme';

const EventItem = ({
  event,
  canJoinSession,
  isLoading: parentLoading,
}) => {
  const { joinParticipant, status } = useJoinParticipant();
  const currentUser = getAuth().currentUser;

  const handleAction = async () => {
    if (!currentUser) {
      alert('Por favor regístrate antes de entrar en una sesión.');
      return;
    }

    await joinParticipant(currentUser, event);
  };

  const backgroundColor = '#e6b800';
  const textColor = theme.palette.getContrastText('#e6b800');

  return (
    <Paper
      elevation={3}
      sx={{
        mb: 2,
        position: 'relative',
        backgroundColor: backgroundColor,
        borderRadius: 1,
        border: '1px solid #e0e0e0',
        transition: 'all 0.3s ease-in-out',
        '&:hover, &.Mui-focusVisible': {
          borderColor: theme.palette.primary.main,
          boxShadow: '0 0 24px #b0c4de',
          backgroundColor: '#ccaa00',
        },
        '&:active': {
          backgroundColor: '#b38f00',
        },
      }}
    >
      <ListItem divider>
        <ListItemIcon>
          <WorkIcon sx={{ color: textColor }} />
        </ListItemIcon>
        <ListItemText
          primary={event.title}
          secondary={`${new Date(event.startDate).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })} - ${new Date(event.endDate).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}`}
          sx={{
            ml: -2,
            '& .MuiListItemText-primary': {
              color: textColor,
              fontWeight: 'bold',
            },
            '& .MuiListItemText-secondary': {
              color: textColor,
            },
          }}
        />

        {canJoinSession && (
          <ListItemIcon sx={{ position: 'absolute', right: 10 }}>
            <IconButton
              edge='end'
              color='primary'
              onClick={handleAction}
              disabled={status.loading || parentLoading}
              aria-label='join-session'
            >
              <ExitToAppIcon sx={{ color: textColor, fontSize: 28 }} />
            </IconButton>
          </ListItemIcon>
        )}
      </ListItem>
    </Paper>
  );
};

export default EventItem;