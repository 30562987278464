import { useState, useEffect } from 'react';
import useFetchCustomSessions from './useFetchCustomSessions';

const useAvailableEvents = () => {
  const [availableEvents, setAvailableEvents] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());

  // Fetch Firebase sessions using the custom hook
  const firebaseSessions = useFetchCustomSessions(currentTime);

  // Update currentTime every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(timer);
  }, []);

  // Process sessions
  useEffect(() => {
    // Remove past sessions
    const upcomingSessions = firebaseSessions.filter(
      (session) => session.endDate > currentTime
    );

    // Sort sessions by startDate
    upcomingSessions.sort((a, b) => a.startDate - b.startDate);

    setAvailableEvents(upcomingSessions);
  }, [firebaseSessions, currentTime]);

  return { availableEvents };
};

export default useAvailableEvents;