import { getAuth, signOut } from 'firebase/auth';

const handleLogout = async (navigate) => {
  const auth = getAuth();
  try {
    await signOut(auth);
    navigate('/', { state: { message: 'Cierre de sesión exitoso' } });
  } catch (error) {
    console.error('Error during logout:', error);
  }
};

export default handleLogout;
