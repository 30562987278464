import React, { useState } from "react";
import { Typography, Box, IconButton, Chip, Snackbar, Alert } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import useDeleteSession from "../hooks/useDeleteSession";
import { db } from "../firebase";
import { useAuth } from "../components/AuthProvider";

const StyledContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const HeaderBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
});

const TitleBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const InfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),
  "& > svg": {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

const StatusChip = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(1),
  alignSelf: "center",
}));

const CustomTooltipContent = ({ appointmentData, onDelete }) => {
  const { deleteSession, deleteStatus } = useDeleteSession(db);
  const { user } = useAuth();
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "info" });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
    localStorage.removeItem("Snackbar");
  };

  const renderSnackbar = () => {
    return (
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    );
  };

  const handleDelete = async () => {
    if (user?.type !== "admin") {
      alert("Only administrators can delete events.");
      return;
    }
    try {
      await deleteSession(appointmentData.id).then(() => {
        onDelete(appointmentData.id);
      });
    } catch (error) {
      console.error("Failed to delete session:", error);
    }
  };

  const isAdmin = user?.type === "admin";

  // Determine if the session is in the past, future, or ongoing
  const now = new Date();
  const sessionStartTime = new Date(appointmentData.startDate);
  const timeDifference = now - sessionStartTime;
  const isSessionInPast = timeDifference > 0;
  const isSessionOngoing = isSessionInPast && timeDifference <= 50 * 60 * 1000; // 50 minutes in milliseconds

  const getSessionStatusMessage = () => {
    if (isSessionOngoing) {
      return "Sesión en curso. Espera a la siguiente sesión para participar";
    } else if (isSessionInPast) {
      return "Esta sesión ya ha terminado. Pronto comienzan más sesiones.";
    } else {
      return "Cuando sea la hora de inicio de la sesión, conéctate y entra a trabajar en lo que quieras.";
    }
  };

  return (
    <StyledContent>
      {renderSnackbar()}
      <HeaderBox>
        <TitleBox>
          <Typography variant="h6" component="h2" sx={{ fontSize: "1.25rem" }}>
            {appointmentData.title}
          </Typography>
        </TitleBox>
        {isAdmin && (
          <IconButton onClick={handleDelete} size="small" color="error">
            <CloseIcon />
          </IconButton>
        )}
      </HeaderBox>
      <Box>
        <InfoBox>
          <EventIcon />
          <Typography variant="body2">{appointmentData.startDate.toLocaleDateString()}</Typography>
        </InfoBox>
        <InfoBox>
          <AccessTimeIcon />
          <Typography variant="body2">
            {`${appointmentData.startDate.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })} - ${appointmentData.endDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`}
          </Typography>
        </InfoBox>
        <InfoBox>
          <MenuBookIcon />
          <Typography variant="body2">
            {getSessionStatusMessage()}
          </Typography>
        </InfoBox>
      </Box>
      {deleteStatus.loading && <StatusChip label="Deleting..." color="secondary" variant="outlined" />}
      {deleteStatus.error && <StatusChip label={deleteStatus.error} color="error" variant="outlined" />}
    </StyledContent>
  );
};

export default CustomTooltipContent;