import React, { useState, useRef } from 'react';
import { Box, Avatar, Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Skeleton } from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { app } from '../firebase';
import { useAuth } from './AuthProvider';

const ProfilePictureUploader = ({ photoURL, setPhotoURL, loading }) => {
  const [image, setImage] = useState(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const editorRef = useRef();
  const fileInputRef = useRef();
  const { updateProfile, fetchUserData } = useAuth();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setEditorOpen(true);
    }
  };

  const handleCrop = async () => {
    if (editorRef.current) {
      setIsSaving(true);
      const canvas = editorRef.current.getImage().toDataURL();
      const response = await fetch(canvas);
      const blob = await response.blob();

      const storage = getStorage(app);
      const auth = getAuth(app);
      const user = auth.currentUser;

      if (user) {
        const storageRef = ref(storage, `profile_pictures/${user.uid}`);
        try {
          await uploadBytes(storageRef, blob);
          const downloadURL = await getDownloadURL(storageRef);

          await updateProfile({ photoURL: downloadURL });
          await fetchUserData(user);

          setPhotoURL(downloadURL);
          
          setEditorOpen(false);
        } catch (error) {
          console.error("Error uploading image: ", error);
          setError("Failed to upload image. Please try again.");
        } finally {
          setIsSaving(false);
        }
      } else {
        console.error("No user authenticated");
        setError("No user authenticated. Please log in and try again.");
        setIsSaving(false);
      }
    }
  };

  return (
    <Box>
      {loading ? (
        <Skeleton variant="circular" width={120} height={120} animation="wave" sx={{ mb: 2 }} />
      ) : (
        <Avatar src={photoURL} alt="Avatar" sx={{ width: 120, height: 120, mb: 2 }} />
      )}
      <input
        ref={fileInputRef}
        hidden
        accept="image/*"
        type="file"
        onChange={handleImageChange}
      />
      <Dialog open={editorOpen} onClose={() => setEditorOpen(false)}>
        <DialogTitle>Cortar imagen</DialogTitle>
        <DialogContent>
          <AvatarEditor
            ref={editorRef}
            image={image}
            width={250}
            height={250}
            border={50}
            borderRadius={125}
            scale={1.2}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditorOpen(false)} disabled={isSaving}>Cancelar</Button>
          <Button 
            onClick={handleCrop} 
            variant="contained" 
            color="primary"
            disabled={isSaving}
            sx={{ 
              bgcolor: isSaving ? 'grey.400' : 'primary.main',
              '&:hover': {
                bgcolor: isSaving ? 'grey.400' : 'primary.dark',
              }
            }}
          >
            {isSaving ? 'Guardando' : 'Guardar'}
          </Button>
        </DialogActions>
      </Dialog>
      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

export default ProfilePictureUploader;