// MobileDrawer.js
import React from 'react';
import { 
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  Typography
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HelpIcon from '@mui/icons-material/Help';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LogoutIcon from '@mui/icons-material/Logout';

const MobileDrawer = ({ open, onClose, user, onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const mainMenuItems = [
    { text: 'Inicio', icon: <HomeIcon />, route: '/homepage' },
    { text: 'Calendario', icon: <CalendarTodayIcon />, route: '/calendar' },
    { text: 'Mi perfil', icon: <PersonIcon />, route: '/profilepage' },
    { text: 'Ayuda', icon: <HelpIcon />, route: '/ayuda' },
    { text: 'Ajustes', icon: <SettingsIcon />, route: '/settings' },
  ];

  const authMenuItems = [
    { text: 'Regístrate', icon: <PersonAddIcon />, route: '/signup' },
    { text: 'Iniciar sesión', icon: <LoginIcon />, route: '/login' },
  ];

  const handleNavigation = (route) => {
    navigate(route);
    onClose();
  };

  const handleLogout = () => {
    onLogout();
    onClose();
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        display: { xs: 'block', md: 'none' }, // Only show on mobile
        '& .MuiDrawer-paper': {
          width: 280,
          backgroundColor: '#001f3f',
          color: 'white',
        },
      }}
    >
      <Box sx={{ py: 2 }}>
        <Typography
          variant="h6"
          sx={{
            px: 2,
            color: '#e6b800',
            fontWeight: 'bold',
          }}
        >
          Menú
        </Typography>
      </Box>
      <Divider sx={{ backgroundColor: 'rgba(255,255,255,0.12)' }} />
      
      <List>
        {user ? (
          // Menu items for logged-in users
          mainMenuItems.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => handleNavigation(item.route)}
                selected={location.pathname === item.route}
                sx={{
                  py: 1.5,
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(255,255,255,0.1)',
                    '&:hover': {
                      backgroundColor: 'rgba(255,255,255,0.15)',
                    },
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.05)',
                  },
                }}
              >
                <ListItemIcon sx={{ color: '#e6b800', minWidth: 40 }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={item.text}
                  sx={{
                    '& .MuiListItemText-primary': {
                      color: 'white',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          // Menu items for non-logged-in users
          authMenuItems.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => handleNavigation(item.route)}
                sx={{
                  py: 1.5,
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.05)',
                  },
                }}
              >
                <ListItemIcon sx={{ color: '#e6b800', minWidth: 40 }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={item.text}
                  sx={{
                    '& .MuiListItemText-primary': {
                      color: 'white',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))
        )}
      </List>

      {user && (
        <>
          <Divider sx={{ backgroundColor: 'rgba(255,255,255,0.12)', mt: 'auto' }} />
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={handleLogout}
                sx={{
                  py: 1.5,
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.05)',
                  },
                }}
              >
                <ListItemIcon sx={{ color: '#e6b800', minWidth: 40 }}>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText 
                  primary="Cerrar sesión"
                  sx={{
                    '& .MuiListItemText-primary': {
                      color: 'white',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </>
      )}
    </Drawer>
  );
};

export default MobileDrawer;