import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

// Import the new logo
import logoSF2 from '../assets/images/logoSF2.png';

const logoStyle = {
  width: '240px', // Increased width
  height: 'auto',
  marginBottom: '20px',
};

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 6 },
        py: { xs: 6, sm: 8 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Box sx={{ mb: 2 }}>
            <Link component={RouterLink} to="/">
              <img
                src={logoSF2}
                style={logoStyle}
                alt="logo of SombraFocus"
              />
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: { xs: 4, sm: 2, md: 4 },
            mt: { xs: 3, sm: 0 },
            mr: { sm: 2, md: 4 }, // Reduced right margin
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="body2" fontWeight={600}>
              Producto
            </Typography>
            <Link color="text.secondary" component={RouterLink} to="/FAQ">
              Preguntas frecuentes
            </Link>
            <Link color="text.secondary" component={RouterLink} to="/contact">
              Contacto
            </Link>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="body2" fontWeight={600}>
              Legal
            </Typography>
            <Link color="text.secondary" component={RouterLink} to="/legal/terms-of-service">
              Términos y condiciones
            </Link>
            <Link color="text.secondary" component={RouterLink} to="/legal/privacy-policy">
              Política de privacidad
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
