import React from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import theme from '../theme';
import logoSF2 from '../assets/images/logoSF2.png';

const WorkInProgressNavbar = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppBar 
        position="absolute" 
        sx={{ 
          backgroundColor: theme.palette.navbar.main,
          height: '64px',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              minHeight: '64px',
              py: 0.25,
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Link to="/" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <img src={logoSF2} alt="Logo" style={{ height: '30px', maxHeight: '100%' }} />
              </Link>
            </Box>

            <Box sx={{ flexGrow: 1 }} />
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

export default WorkInProgressNavbar;