import React from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Navbar from "../components/Navbar";
import Typography from '@mui/material/Typography';
import HighlightOffIcon from '@mui/icons-material/HighlightOff'; // Import cross icon

function PaymentFailurePage() {
  return (
    <>
      <Navbar />
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center', 
          minHeight: '100vh', 
          paddingTop: '40px', 
          paddingBottom: '32px', 
          background: 'linear-gradient(to bottom, #ffffff, #f5f9fc)',
          textAlign: 'center'
        }}
      >
        <HighlightOffIcon sx={{ fontSize: 60, color: 'red' }} /> {/* Negative cross icon */}
        <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
          Lo sentimos, el pago no se ha podido realizar
        </Typography>
        <Typography variant="subtitle1">
          Puedes volver a intentarlo <Link to="/pricing">inicio</Link> desde aquí. Si tienes problemas con el pago y la suscripción, escribe a contacto@sessionfocus.com y lo solucionamos inmediatamente.
        </Typography>
      </Box>
    </>
  );
}

export default PaymentFailurePage;

