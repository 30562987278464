import React from "react";
import LogIn from "../components/LogIn";
import Navbar from "../components/Navbar";
import Box from '@mui/material/Box';

function LogInPage() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar />
      <Box 
        sx={{ 
          flexGrow: 1, 
          paddingTop: '40px', 
          paddingBottom: '32px', 
          background: 'linear-gradient(to bottom, #ffffff, #e6edf2)', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center'
        }}
      >
        <LogIn />
      </Box>
    </Box>
  );
}

export default LogInPage;
