import { getAuth, signInWithPopup, GoogleAuthProvider, updateProfile } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { app } from '../firebase';

const handleGoogleSignUp = async () => {
  const auth = getAuth(app);
  const db = getFirestore(app);
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;


    const displayName = user.displayName || '';
    const [firstName, ...lastNameParts] = displayName.split(' ');
    const lastName = lastNameParts.join(' ');
    const username = displayName;

    // Check if the user already exists in Firestore
    const userDoc = await getDoc(doc(db, "users", user.uid));
    let isNewUser = false;

    if (!userDoc.exists()) {
      // If the user doesn't exist, mark as new user
      isNewUser = true;

      // Update the user's profile with their display name if it's not set
      if (!user.displayName) {
        await updateProfile(user, {
          displayName: username,
          photoURL: user.photoURL
        });
      }

      // Create a new user document in Firestore
      await setDoc(doc(db, "users", user.uid), {
        info: {
          firstName: firstName,
          lastName: lastName,
          email: user.email,
          username: username,
          photoURL: user.photoURL || '',
          role: '', // Will be set after role selection
          type: "regular",
          createdAt: serverTimestamp(),
          firstTime: true
        }
      });

      // Refresh user data in the AuthProvider
      const updatedUser = await auth.currentUser;
      await auth.updateCurrentUser(updatedUser);
    } else {
      // If the user exists, update their information if needed
      const userData = userDoc.data();
      const updateData = {};

      if (!userData.info.firstName || !userData.info.lastName) {
        updateData['info.firstName'] = firstName;
        updateData['info.lastName'] = lastName;
      }

      if (user.photoURL && user.photoURL !== userData.info.photoURL) {
        updateData['info.photoURL'] = user.photoURL;
      }

      if (!userData.info.type) {
        updateData['info.type'] = "regular";
      }

      if (Object.keys(updateData).length > 0) {
        await setDoc(doc(db, "users", user.uid), updateData, { merge: true });
      }
    }

    return { user, firstName, lastName, username, isNewUser };
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error('Error during Google sign up:', errorCode, errorMessage);
    throw error;
  }
};

export default handleGoogleSignUp;