// hooks/useEarlyRoomSituation.js

import { useState, useEffect } from 'react';
import { ref, onValue, off } from 'firebase/database';
import { realtimeDb } from '../firebase'; // Adjust the path as needed

const useEarlyRoomSituation = (sessionID) => {
  const [room, setRoom] = useState(null);

  // Helper function to extract room number from room string
  const extractRoomNumber = (roomString) => {
    const match = roomString.match(/\d+/);
    return match ? match[0].padStart(2, '0') : null;
  };

  useEffect(() => {
    if (!sessionID) {
      setRoom(null);
      return;
    }

    const roomsRef = ref(realtimeDb, `sessions/${sessionID}/rooms`);

    const handleValueChange = (snapshot) => {
      const roomsData = snapshot.val();
      console.log('Fetched roomsData:', roomsData); // Debugging log

      if (roomsData) {
        // Compute roomSituation
        const roomSituation = Object.entries(roomsData).reduce((acc, [roomNumber, roomData]) => {
          console.log(`Processing ${roomNumber}:`, roomData); // Debugging log

          // Ensure roomData.users exists and is an object
          if (roomData.users && typeof roomData.users === 'object') {
            const activeUsers = Object.values(roomData.users).filter(user => {
              console.log(`User presence for ${roomNumber}:`, user.presence); // Debugging log
              return user.presence === true;
            }).length;
            acc[roomNumber] = activeUsers;
          } else {
            acc[roomNumber] = 0;
          }

          return acc;
        }, {});

        console.log('Computed roomSituation:', roomSituation); // Debugging log

        // Determine the assigned room based on criteria
        const sortedRooms = Object.keys(roomSituation).sort((a, b) => {
          const numA = parseInt(a.replace(/\D/g, ''), 10);
          const numB = parseInt(b.replace(/\D/g, ''), 10);
          return numA - numB;
        });

        console.log('Sorted rooms:', sortedRooms); // Debugging log

        let roomToAssign = null;
        for (const roomKey of sortedRooms) {
          if (roomSituation[roomKey] < 2) {
            roomToAssign = roomKey;
            console.log(`Assigning to room: ${roomKey}`); // Debugging log
            break;
          }
        }

        if (roomToAssign) {
          const roomNumber = extractRoomNumber(roomToAssign);
          setRoom(roomNumber);
        } else {
          // All existing rooms are full. Recommend the next room number.
          if (sortedRooms.length > 0) {
            const lastRoomNumber = sortedRooms[sortedRooms.length - 1];
            const lastNumber = parseInt(lastRoomNumber.replace(/\D/g, ''), 10);
            const nextNumber = lastNumber + 1;
            const nextRoom = `room${String(nextNumber).padStart(2, '0')}`;
            const recommendedRoomNumber = extractRoomNumber(nextRoom);
            setRoom(recommendedRoomNumber);
            console.log(`All rooms full. Recommended room: ${recommendedRoomNumber}`); // Debugging log
          } else {
            // No rooms exist yet. Recommend the first room.
            setRoom('01');
            console.log('No rooms exist. Recommended room: 01'); // Debugging log
          }
        }
      } else {
        setRoom('01'); // Recommend first room if no rooms exist
        console.log('No rooms data found. Recommended room: 01'); // Debugging log
      }
    };

    onValue(roomsRef, handleValueChange);

    // Cleanup function to remove the listener
    return () => {
      off(roomsRef, 'value', handleValueChange);
    };
  }, [sessionID]);

  return room;
};

export default useEarlyRoomSituation;
