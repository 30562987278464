import { createTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import '@fontsource/raleway';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0050ef',
    },
    navbar: {
      main: '#001f3f',
      button: '#e6b800',
      buttonHover: '#cca300',
      buttonText: '#2C3E50',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 'bold',
    },
    h3: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 'bold',
    },
    h4: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 'bold',
    },
    h5: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 'bold',
    },
    h6: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 'bold',
    },
    subtitle1: {
      fontFamily: 'Raleway, sans-serif',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#f0f0f0',
          borderRadius: 10,
          border: '1px solid #e0e0e0',
          boxShadow: 'none',
          transition: 'background-color 0.2s, border 0.2s, box-shadow 0.2s',
          '&:hover, &.Mui-focusVisible': {
            borderColor: '#0050ef',
            boxShadow: '0 0 24px #b0c4de',
            backgroundColor: alpha('#0050ef', 0.1),
          },
          '&:active': {
            backgroundColor: alpha('#0050ef', 0.2),
          }
        }
      }
    }
  }
});

export default theme;
