// hooks/useRoomSituation.js

import { useState, useEffect } from 'react';
import { ref, onValue, off } from 'firebase/database';
import { realtimeDb } from '../firebase'; // Adjust the path as necessary
import useEarlyRoomSituation from './useEarlyRoomSituation';
import useLateRoomSituation from './useLateRoomSituation';

const useRoomSituation = (sessionID) => {
  const [room, setRoom] = useState(null);
  const [sessionStartTime, setSessionStartTime] = useState(null);

  // Fetch room assignments from both hooks
  const earlyRoom = useEarlyRoomSituation(sessionID);
  const lateRoom = useLateRoomSituation(sessionID);

  useEffect(() => {
    if (!sessionID) {
      setRoom(null);
      return;
    }

    // Fetch session start time from Firebase
    const sessionRef = ref(realtimeDb, `sessions/${sessionID}`);

    const unsubscribe = onValue(sessionRef, (snapshot) => {
      const sessionData = snapshot.val();
      if (sessionData && sessionData.startDate) {
        const startTime = new Date(sessionData.startDate);
        setSessionStartTime(startTime);
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [sessionID]);

  useEffect(() => {
    if (!sessionStartTime) {
      return;
    }

    const now = new Date();
    const ninetySecondsAfterStart = sessionStartTime.getTime() + 90 * 1000;

    if (now.getTime() <= ninetySecondsAfterStart) {
      // Within 90 seconds after the session start time
      setRoom(earlyRoom);
    } else {
      // More than 90 seconds after the session start time
      setRoom(lateRoom);
    }
  }, [sessionStartTime, earlyRoom, lateRoom]);

  return room;
};

export default useRoomSituation;
