import React, { useState, useEffect } from "react";
import { Paper, Typography, Box, Skeleton, IconButton } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import ProfilePictureUploader from "./ProfilePictureUploader";
import ProfileForm from "./ProfileForm";
import { useAuth } from "./AuthProvider";
import useSessionCount from "../hooks/useSessionCount";

const ProfileCard = ({ isEditing, editedProfile, handleProfileChange, loading }) => {
  const { user } = useAuth();
  const [avatarLoading, setAvatarLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const { sessionCount, loading: sessionCountLoading } = useSessionCount(() => {
    const cachedData = sessionStorage.getItem("sessionCount");
    return cachedData ? cachedData : null;
  });

  useEffect(() => {
    if (!loading) {
      setAvatarLoading(false);
    }
  }, [loading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleProfileChange(name, value);
    setValidationErrors((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <Paper sx={{ p: 3, mb: 3, borderRadius: 4, boxShadow: 3 }}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
        {loading ? (
          <>
            <Skeleton variant="circular" width={100} height={100} animation="wave" />
            <Skeleton variant="text" width="60%" height={40} animation="wave" />
            <Skeleton variant="text" width="80%" height={30} animation="wave" />
          </>
        ) : (
          <>
            <Box sx={{ position: "relative" }}>
              <ProfilePictureUploader
                photoURL={editedProfile.photoURL}
                setPhotoURL={(url) => handleProfileChange("photoURL", url)}
                loading={avatarLoading}
              />
              {isEditing && (
                <IconButton
                  color="primary"
                  aria-label="edit picture"
                  component="span"
                  sx={{ position: "absolute", bottom: 0, right: 0 }}
                  onClick={() => {
                    const fileInput = document.querySelector('input[type="file"]');
                    if (fileInput) fileInput.click();
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </Box>
            <Box sx={{ width: "100%", textAlign: "center" }}>
              {isEditing ? (
                <ProfileForm
                  editedProfile={editedProfile}
                  handleChange={handleChange}
                  validationErrors={validationErrors}
                />
              ) : (
                <Typography variant="h4" gutterBottom sx={{ mb: 0.5 }}>
                  {user.firstName} {user.lastName}
                </Typography>
              )}
            </Box>
            <Typography variant="h5" color="text.secondary" sx={{ mt: 1, mb: 2, fontWeight: "bold" }}>
              {localStorage.getItem("sessionCount") ? (
                `${localStorage.getItem("sessionCount")} sesiones`
              ) : sessionCountLoading ? (
                <Skeleton variant="text" width={100} />
              ) : (
                `${sessionCount} sesiones`
              )}
            </Typography>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default ProfileCard;
