import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app } from '../firebase';
import { Box, AppBar, Toolbar, IconButton } from '@mui/material';
import Navbar from '../components/Navbar';
import MyProfile from '../components/MyProfile';
import AppDrawer from '../components/AppDrawer';

const ProfilePage = () => {
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    description: 'A passionate developer who loves to create amazing user experiences.',
    country: 'USA',
    favoriteMovie: 'Inception',
    favoriteBook: 'The Great Gatsby',
    favoriteMusician: 'John Mayer',
    workingOn: 'Building a new MUI project',
    photoURL: ''
  });

  const [loading, setLoading] = useState(true);
  const [isDrawerRight, setIsDrawerRight] = useState(false);

  useEffect(() => {
    const auth = getAuth(app);
    const db = getFirestore(app);

    const fetchProfileData = async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data().info;
            setProfile((prevProfile) => ({
              ...prevProfile,
              firstName: userData.firstName,
              lastName: userData.lastName,
              photoURL: userData.photoURL || '',
              description: userData.description || prevProfile.description,
              country: userData.country || prevProfile.country,
              favoriteMovie: userData.favoriteMovie || prevProfile.favoriteMovie,
              favoriteBook: userData.favoriteBook || prevProfile.favoriteBook,
              favoriteMusician: userData.favoriteMusician || prevProfile.favoriteMusician,
              workingOn: userData.workingOn || prevProfile.workingOn,
            }));
          } else {
            console.log("No user document found");
          }
        } catch (error) {
          console.error("Error fetching user data from Firestore: ", error);
        }
      } else {
        console.log('No user is signed in.');
      }
      setLoading(false);
    };

    onAuthStateChanged(auth, (user) => {
      fetchProfileData(user);
    });
  }, []);

  const toggleDrawerPosition = () => {
    setIsDrawerRight(!isDrawerRight);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
      <AppBar 
        position="static" 
        elevation={0} 
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar disableGutters sx={{ bgcolor: "#fff" }}>
          <Navbar profile={profile} />
          <IconButton onClick={toggleDrawerPosition}>
            {isDrawerRight ? 'Left' : 'Right'}
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
        {!isDrawerRight && (
          <AppDrawer isRight={isDrawerRight} />
        )}

        <Box sx={{ 
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          overflow: 'auto',
          p: 2,
          background: 'linear-gradient(to bottom, #ffffff, #e0e8ef)', 
        }}>
          <MyProfile profile={profile} setProfile={setProfile} loading={loading} />
        </Box>

        {isDrawerRight && (
          <AppDrawer isRight={isDrawerRight} />
        )}
      </Box>
    </Box>
  );
};

export default ProfilePage;
