import React from "react";
import { Typography, Link as MuiLink } from "@mui/material";

function LegalTerms({ domain, companyName }) {
  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Términos de Servicio
      </Typography>
      <div style={{ marginBottom: '20px' }}></div>
      <Typography paragraph>
        Bienvenido a <strong>SessionFocus</strong> ("nosotros", "nuestro" o "la Aplicación"). Al
        utilizar nuestra aplicación y servicios, aceptas cumplir y estar sujeto
        a los siguientes Términos de Servicio. Si no estás de acuerdo con estos
        términos, no utilices nuestra aplicación.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        1. Aceptación de los Términos
      </Typography>
      <Typography paragraph>
        Al registrarte en <strong>SessionFocus</strong> y utilizar nuestros servicios, aceptas estar
        legalmente vinculado por estos Términos de Servicio, así como por
        nuestra Política de Privacidad.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        2. Descripción del Servicio
      </Typography>
      <Typography paragraph>
        <strong>SessionFocus</strong> proporciona una plataforma en línea donde los usuarios pueden
        registrarse y participar en sesiones de estudio o trabajo en línea.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        3. Registro y Cuenta
      </Typography>
      <Typography paragraph>
        - La información proporcionada durante el registro debe ser precisa y
        completa. Eres responsable de mantener la confidencialidad de tu cuenta
        y contraseña. <br />
        - Notifícanos inmediatamente cualquier uso no autorizado de tu cuenta.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        4. Uso Aceptable
      </Typography>
      <Typography paragraph>
        - No puedes utilizar nuestra aplicación para actividades ilegales,
        dañinas o abusivas. <br />
        - No debes interferir con el funcionamiento de la aplicación o intentar
        obtener acceso no autorizado a nuestros sistemas. <br />
        - El objeto de las sesiones es exclusivamente el trabajo. Por lo tanto, no puedes realizar comentarios o actitudes inapropiadas durante ellas. Si ves a un usuario comportarse de manera inapropiado, puedes bloquearlo y además denunciarlo a nosotros a través del contacto.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        5. Contenido del Usuario
      </Typography>
      <Typography paragraph>
        - Eres responsable del contenido que publicas en la aplicación. <br />
        - Al publicar contenido, nos otorgas una licencia no exclusiva,
        mundial, libre de regalías y transferible para usar, reproducir,
        distribuir, preparar trabajos derivados de, mostrar y realizar dicho
        contenido en relación con la provisión de los servicios de la
        aplicación.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        6. Propiedad Intelectual
      </Typography>
      <Typography paragraph>
        - Todos los derechos de propiedad intelectual en la aplicación y su
        contenido, excluyendo el contenido del usuario, son propiedad de <strong>SessionFocus</strong> o sus licenciantes.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        7. Terminación del Servicio
      </Typography>
      <Typography paragraph>
        - Podemos suspender o terminar tu acceso a la aplicación en cualquier
        momento, sin previo aviso, por cualquier motivo, incluyendo, entre
        otros, la violación de estos Términos de Servicio.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        8. Limitación de Responsabilidad
      </Typography>
      <Typography paragraph>
        - <strong>SessionFocus</strong> no será responsable de ningún daño indirecto, incidental, especial,
        consecuente o punitivo, o cualquier pérdida de beneficios o ingresos,
        ya sea incurrido directamente o indirectamente, o cualquier pérdida de
        datos, uso, buena voluntad, o cualquier otra pérdida intangible,
        resultante de (i) tu uso o incapacidad de usar la aplicación; (ii)
        cualquier acceso no autorizado o uso de nuestros servidores y/o
        cualquier información personal almacenada en ellos; (iii) cualquier
        interrupción o cese de transmisión hacia o desde la aplicación.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        9. Modificaciones a los Términos de Servicio
      </Typography>
      <Typography paragraph>
        - Nos reservamos el derecho de modificar estos Términos de Servicio en
        cualquier momento. Publicaremos los términos revisados en nuestra
        aplicación y la fecha de la última actualización. Es tu responsabilidad
        revisar estos términos periódicamente.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        10. Ley Aplicable
      </Typography>
      <Typography paragraph>
        - Estos Términos de Servicio se regirán e interpretarán de acuerdo con
        las leyes de Chile, sin dar efecto a ningún principio de conflictos
        de leyes.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        11. Contacto
      </Typography>
      <Typography paragraph>
        - Si tienes alguna pregunta sobre estos Términos de Servicio, por favor
        contáctanos a contacto@sessionfocus.com
      </Typography>
    </>
  );
}

export default LegalTerms;
