// MyUILayout.js
import React from 'react';
import {
  CallingState,
  SpeakerLayout,
  StreamTheme,
  useCallStateHooks,
} from '@stream-io/video-react-sdk';
import CallControls from './CallControls';
import useRedistribution from '../hooks/useRedistribution';

const MyUILayout = ({ handleLeaveCall, sessionId, roomId, user }) => {
  const { useCallCallingState } = useCallStateHooks();
  const callingState = useCallCallingState();

  // Retain the useRedistribution hook
  useRedistribution({
    sessionId,
    roomId,
    user,
    handleLeaveCall,
  });

  if (callingState !== CallingState.JOINED) {
    return <div>Loading...</div>;
  }

  return (
    <StreamTheme>
      <div className="my-ui-layout">
        <div className="speaker-layout-container">
          <SpeakerLayout participantsBarPosition="bottom" />
        </div>
        <CallControls handleLeaveCall={handleLeaveCall} />
      </div>
    </StreamTheme>
  );
};

export default MyUILayout;
