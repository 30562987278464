import React, { useState } from "react";
import { Box, Alert, Grid, TextField, Button, CircularProgress } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import contact from "./../util/contact"; // Ensure this path is correct

function Contact(props) {
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const { handleSubmit, control, reset, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    setPending(true);
    contact
      .submit(data)
      .then(() => {
        reset();
        setFormAlert({
          type: "success",
          message: "Su mensaje ha sido mandado!",
        });
      })
      .catch((error) => {
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {props.showNameField && (
            <Grid item xs={12} md={6}>
              <Controller
                name="name"
                control={control}
                rules={{ required: "Please enter your name" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="Nombre"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} md={props.showNameField ? 6 : 12}>
            <Controller
              name="email"
              control={control}
              rules={{ required: "Please enter your email" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  type="email"
                  label="Email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="message"
              control={control}
              rules={{ required: "Please enter a message" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="Mensaje"
                  multiline
                  rows={5}
                  error={!!errors.message}
                  helperText={errors.message?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={pending}
            >
              {!pending && <span>{props.buttonText}</span>}
              {pending && <CircularProgress size={28} />}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default Contact;
