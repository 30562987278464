import { useState, useEffect } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useAuth } from "../components/AuthProvider";

const useSessionCount = () => {
  const [sessionCount, setSessionCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchSessionCount = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        const db = getFirestore();
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const joinedSessions = userData.joinedSessions || [];
          setSessionCount(joinedSessions.length);
          localStorage.setItem("sessionCount", joinedSessions.length);
        } else {
          console.log("No such user document!");
          setSessionCount(0);
          localStorage.setItem("sessionCount", 0);
        }
      } catch (error) {
        console.error("Error fetching session count:", error);
        setSessionCount(0);
        localStorage.setItem("sessionCount", 0);
      } finally {
        setLoading(false);
      }
    };

    fetchSessionCount();

    const handleBeforeUnload = () => {
      localStorage.removeItem("sessionCount");
      console.log("Removed from localStorage on refresh");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [user]);

  return { sessionCount, loading };
};

export default useSessionCount;