import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { app } from '../firebase';

const useFetchUserToken = () => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchUserAndToken = async () => {
      const auth = getAuth(app);
      const currentUser = auth.currentUser;

      if (currentUser) {
        setUser(currentUser);
        try {
          const response = await fetch(
            'https://us-central1-sessionfocus-ea1ce.cloudfunctions.net/getStreamToken',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ userId: currentUser.uid }),
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setToken(data.token);
        } catch (error) {
          console.error('Error fetching token:', error);
        }
      } else {
        console.error('No authenticated user found.');
      }
    };

    fetchUserAndToken();
  }, []);

  return { user, token };
};

export default useFetchUserToken;
