import { useState, useEffect, useCallback } from 'react';
import { ref, onValue, off, get, query, orderByChild } from 'firebase/database';
import { realtimeDb } from '../firebase';
import useDetermineTargetRoom from './useDetermineTargetRoom';
import { useNavigate } from 'react-router-dom';

const useRedistribution = ({
  sessionId,
  roomId,
  user,
  handleLeaveCall,
}) => {
  const [shouldRedistribute, setShouldRedistribute] = useState(false);
  const [roomDistribution, setRoomDistribution] = useState([]);
  const [sessionStartTime, setSessionStartTime] = useState(null);
  const navigate = useNavigate();

  const targetRoom = useDetermineTargetRoom(roomDistribution, roomId);

  const getActiveUserCount = useCallback(async (roomId) => {
    const usersRef = ref(realtimeDb, `sessions/${sessionId}/rooms/${roomId}/users`);
    const snapshot = await get(usersRef);
    const usersData = snapshot.val();
    return usersData
      ? Object.values(usersData).filter(user => user.presence === true).length
      : 0;
  }, [sessionId]);

  useEffect(() => {
    const sessionRef = ref(realtimeDb, `sessions/${sessionId}`);
    const sessionQuery = query(sessionRef, orderByChild('startDate'));
    
    const handleSessionData = (snapshot) => {
      const sessionData = snapshot.val();
      if (sessionData && sessionData.startDate) {
        setSessionStartTime(new Date(sessionData.startDate));
      }
    };

    onValue(sessionQuery, handleSessionData);

    return () => {
      off(sessionQuery, 'value', handleSessionData);
    };
  }, [sessionId]);

  useEffect(() => {
    const sessionRoomsRef = ref(realtimeDb, `sessions/${sessionId}/rooms`);

    const handleValueChange = async (snapshot) => {
      const roomsData = snapshot.val() || {};
      const distribution = await Promise.all(Object.keys(roomsData).map(async (roomId) => {
        const count = await getActiveUserCount(roomId);
        return { roomId, count };
      }));

      setRoomDistribution(distribution);
      
      const currentRoomData = distribution.find(room => room.roomId === roomId);
      if (currentRoomData) {
        setShouldRedistribute(currentRoomData.count === 1);
      }
    };

    onValue(sessionRoomsRef, handleValueChange);

    return () => {
      off(sessionRoomsRef, 'value', handleValueChange);
    };
  }, [sessionId, roomId, getActiveUserCount]);

  useEffect(() => {
    if (
      sessionStartTime &&
      shouldRedistribute &&
      targetRoom &&
      targetRoom !== 'stay' &&
      targetRoom !== 'alone'
    ) {
      const redistributionTime = sessionStartTime.getTime() + 90000; // 90 seconds after session start
      const now = Date.now();
      const timeUntilRedistribution = redistributionTime - now;

      if (timeUntilRedistribution > 0) {
        const timer = setTimeout(() => {
          navigate(`/sessionroom/${sessionId}${targetRoom.slice(-2)}`);
        }, timeUntilRedistribution);

        return () => clearTimeout(timer);
      }

      // Removed immediate redistribution logic
    }
  }, [sessionStartTime, shouldRedistribute, targetRoom, sessionId, navigate]);

  // No values are returned since we're not displaying the status
};

export default useRedistribution;
