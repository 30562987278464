import React from "react";
import { Box, Container, Tabs, Tab } from "@mui/material";
import { Link, useParams, Navigate } from "react-router-dom";
import LegalTerms from "./LegalTerms";
import LegalPrivacy from "./LegalPrivacy";

function LegalSection() {
  const { section = "terms-of-service" } = useParams();

  const data = {
    domain: "company.com",
    companyName: "Company",
  };

  if (section !== "terms-of-service" && section !== "privacy-policy") {
    return <Navigate to="/legal/terms-of-service" replace />;
  }

  return (
    <Box sx={{ bgcolor: "background.paper", py: 4 }}>
      <Container>
        <Tabs
          value={section}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            component={Link}
            to="/legal/terms-of-service"
            label="Términos de servicio"
            value="terms-of-service"
          />
          <Tab
            component={Link}
            to="/legal/privacy-policy"
            label="Política de Privacidad"
            value="privacy-policy"
          />
        </Tabs>
        <Box mt={5}>
          {section === "terms-of-service" && <LegalTerms {...data} />}
          {section === "privacy-policy" && <LegalPrivacy {...data} />}
        </Box>
      </Container>
    </Box>
  );
}

export default LegalSection;