// useAddEventPack.js
import { useState } from "react";
import { realtimeDb } from '../firebase';
import { ref, set, push } from 'firebase/database';
import toast from "react-hot-toast";
import moment from 'moment';
import 'moment/locale/es';  // Import Spanish locale

const useAddEventPack = () => {
  // Set moment to use Spanish locale
  moment.locale('es');

  const [eventPackData, setEventPackData] = useState({
    date: new Date(),
  });

  const addEventPack = async (eventPackData) => {
    try {
      console.log("Adding event pack with data:", eventPackData);

      // Time slots for the sessions
      const timeSlots = ['16:00', '17:00', '18:00', '19:00', '20:00', '21:00'];
      
      // Get the day name in Spanish
      const dayName = moment(eventPackData.date).format('dddd');
      const spanishDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1); // Capitalize first letter

      // Create an event for each time slot
      for (const timeSlot of timeSlots) {
        const [hours, minutes] = timeSlot.split(':');
        
        // Create start date time
        const startDateTime = moment(eventPackData.date)
          .set('hour', parseInt(hours))
          .set('minute', parseInt(minutes))
          .set('second', 0)
          .toDate();

        // Create end date time (50 minutes later)
        const endDateTime = moment(startDateTime).add(50, 'minutes').toDate();

        // Generate the title with Spanish day name and time
        const eventTitle = `${spanishDayName} ${timeSlot}`;

        // Generate a custom ID using the sessionsRef
        const sessionsRef = ref(realtimeDb, 'sessions');
        const newEventRef = push(sessionsRef);
        const customId = newEventRef.key;

        // Add event to Realtime Database
        const eventRealtimeDbRef = ref(realtimeDb, `sessions/${customId}`);
        await set(eventRealtimeDbRef, {
          title: eventTitle,
          startDate: startDateTime.toISOString(),
          endDate: endDateTime.toISOString(),
        });

        console.log("Event added to Realtime Database with ID: ", customId);
      }

      setEventPackData({
        date: new Date(),
      });
      
      toast.success("Event pack added successfully!");
      return { success: true };
    } catch (error) {
      console.error("Error adding event pack:", error);
      toast.error(`Error adding event pack: ${error.message}`);
      return { success: false, error: error.message };
    }
  };

  return {
    eventPackData,
    setEventPackData,
    addEventPack,
  };
};

export default useAddEventPack;