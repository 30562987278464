// AppDrawer.js
import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HelpIcon from '@mui/icons-material/Help';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const drawerWidth = 100;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function AppDrawer({ isRight = false }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuItems = [
    { text: 'Inicio', icon: <HomeIcon />, route: '/homepage' },
    { text: 'Calendario', icon: <CalendarTodayIcon />, route: '/calendar' },
    { text: 'Mi perfil', icon: <PersonIcon />, route: '/profilepage' },
    { text: 'Ayuda', icon: <HelpIcon />, route: '/ayuda' },
    { text: 'Ajustes', icon: <SettingsIcon />, route: '/settings' },
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: '#001f3f',
          transition: '2s ease-in-out',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Drawer
          className="max-md:w-[80px]"
          PaperProps={{
            style: {
              backgroundColor: '#001f3f',
              transition: 'ease-in-out',
              transitionDuration: '1.5s',
              paddingTop: '40px',
              display: { xs: 'none', md: 'block' },
            },
          }}
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              width: drawerWidth,
            },
          }}
          onMouseLeave={handleDrawerClose}
          onMouseEnter={handleDrawerOpen}
          variant="permanent"
          open={true}
          anchor={isRight ? 'right' : 'left'}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {/* Optional: Icon to close drawer */}
            </IconButton>
          </DrawerHeader>
          <List sx={{ marginTop: '20px' }}>
            {menuItems.map((item) => {
              const isSelected = location.pathname === item.route;
              return (
                <ListItem
                  key={item.text}
                  disablePadding
                  sx={{ display: 'block', py: 1, flexDirection: 'column' }}
                >
                  <ListItemButton
                    onClick={() => navigate(item.route)}
                    sx={{
                      minHeight: 64,
                      gap: 0.5,
                      flexDirection: 'column',
                      alignItems: 'center !important',
                      justifyContent: 'center',
                      px: 2.5,
                      mx: 2,
                      borderRadius: '10px',
                      transition: 'background-color 0.3s ease-in-out',
                      backgroundColor: isSelected
                        ? 'rgba(255, 255, 255, 0.2)'
                        : 'transparent',
                      '&:hover': {
                        backgroundColor: isSelected
                          ? 'rgba(255, 255, 255, 0.2)'
                          : 'rgba(255, 255, 255, 0.1)',
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: 'center',
                        color: '#e6b800',
                        transition: 'color 0.3s ease-in-out',
                        '&:hover': {
                          color: '#ffffff',
                        },
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <Typography
                      sx={{
                        opacity: 1,
                        color: '#ffffff',
                        transition: 'color 0.3s ease-in-out',
                        fontFamily: theme.typography.h1.fontFamily,
                        fontWeight: 'bold',
                        fontSize: 12,
                        '&:hover': {
                          color: '#e6b800',
                        },
                      }}
                    >
                      {item.text}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Drawer>
      </div>
    </>
  );
}

AppDrawer.propTypes = {
  isRight: PropTypes.bool,
};