import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, Paper, useTheme } from '@mui/material';
import { ref, onValue } from 'firebase/database';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { realtimeDb } from '../firebase';
import { getAuth } from 'firebase/auth';
import { app } from '../firebase';

// Import all audio files
import finalAudio from '../assets/audios/exito.mp3';
import startAudio from '../assets/audios/start.mp3';
import fantasiaAudio from '../assets/audios/fantasia.mp3';
import arpaAudio from '../assets/audios/arpa.mp3';
import nivelCompletadoAudio from '../assets/audios/nivelcompletado.mp3';
import gameOverAudio from '../assets/audios/gameover.mp3';
import notificacionAudio from '../assets/audios/notificacion.mp3';
import pianoAudio from '../assets/audios/piano.mp3';

const audioMap = {
  'exito': finalAudio,
  'start': startAudio,
  'fantasia': fantasiaAudio,
  'arpa': arpaAudio,
  'nivelcompletado': nivelCompletadoAudio,
  'gameover': gameOverAudio,
  'notificacion': notificacionAudio,
  'piano': pianoAudio,
};

const volumeMap = {
  'exito': 0.7,
  'start': 0.7,
  'fantasia': 1.0,
  'arpa': 1.0,
  'nivelcompletado': 1.0,
  'gameover': 0.7,
  'notificacion': 0.7,
  'piano': 0.7,
};

const CountdownTimer = ({ sessionId }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [sessionStartTime, setSessionStartTime] = useState(null);
  const [sessionEndTime, setSessionEndTime] = useState(null);
  const [sessionStarted, setSessionStarted] = useState(false);
  const [sessionEnded, setSessionEnded] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const audioRef = useRef(null);
  const audioPlayedRef = useRef(false);
  const theme = useTheme();

  useEffect(() => {
    // Fetch user's audio preference
    const fetchAudioPreference = async () => {
      const auth = getAuth(app);
      const user = auth.currentUser;
      
      if (user) {
        const db = getFirestore(app);
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists() && userDoc.data().info?.audioPreference) {
            const preference = userDoc.data().info.audioPreference;
            const audioSrc = audioMap[preference];
            const volume = volumeMap[preference];
            
            // Create new audio instance with selected preference
            audioRef.current = new Audio(audioSrc);
            audioRef.current.volume = volume;
          } else {
            // Fallback to default audio
            audioRef.current = new Audio(finalAudio);
            audioRef.current.volume = 0.7;
          }
        } catch (error) {
          console.error("Error fetching audio preference:", error);
          // Fallback to default audio
          audioRef.current = new Audio(finalAudio);
          audioRef.current.volume = 0.7;
        }
      }
    };

    fetchAudioPreference();

    // Fetch session data from Firebase
    const fetchSessionData = () => {
      const sessionRef = ref(realtimeDb, `sessions/${sessionId}`);
      
      const unsubscribe = onValue(sessionRef, (snapshot) => {
        const sessionData = snapshot.val();
        if (sessionData) {
          const startTime = new Date(sessionData.startDate);
          const endTime = new Date(sessionData.endDate);
          setSessionStartTime(startTime);
          setSessionEndTime(endTime);
        }
      });

      return unsubscribe;
    };

    const unsubscribe = fetchSessionData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      // Reset audio on component unmount
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, [sessionId]);

  useEffect(() => {
    if (!sessionStartTime || !sessionEndTime) {
      return;
    }

    // Set up timer interval
    const timer = setInterval(() => {
      const now = new Date();
      let difference;
      let targetTime;

      // Determine current session state
      if (now < sessionStartTime) {
        difference = sessionStartTime.getTime() - now.getTime();
        targetTime = sessionStartTime;
        setSessionStarted(false);
      } else if (now < sessionEndTime) {
        difference = sessionEndTime.getTime() - now.getTime();
        targetTime = sessionEndTime;
        setSessionStarted(true);
      } else {
        setSessionEnded(true);
        clearInterval(timer);
        // Play end of session audio
        if (!audioPlayedRef.current && audioRef.current) {
          audioRef.current.play().catch(error => {
            console.error('Error playing audio:', error);
          });
          audioPlayedRef.current = true;
        }
        return;
      }

      // Calculate and set remaining time
      const minutes = Math.floor(difference / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      setTimeLeft(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [sessionStartTime, sessionEndTime]);

  if (!timeLeft && !sessionEnded) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '6px 16px',
          height: '36px',
          backgroundColor: sessionStarted ? theme.palette.secondary.main : theme.palette.primary.main,
          color: sessionStarted ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText,
          borderRadius: '10px',
          cursor: 'default',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: sessionStarted ? theme.palette.secondary.dark : theme.palette.primary.dark,
          },
        }}
      >
        <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
          {sessionEnded ? 'Sesión finalizada' : (sessionStarted ? 'Termina en:' : 'Comienza en:')}
        </Typography>
        {!sessionEnded && (
          <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', letterSpacing: 1 }}>
            {timeLeft}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default CountdownTimer;