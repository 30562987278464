import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FAQ from "../components/FAQ";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function FAQPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        background: "linear-gradient(to bottom, #ffffff, #f5f9fc)",
      }}
    >
      <Navbar />
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            paddingTop: "40px",
          }}
        >
          <FAQ />
        </Box>
      </Box>
      <Box>
        <Divider />
        <Footer />
      </Box>
    </Box>
  );
}

export default FAQPage;