import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { app } from '../firebase';
import { Box, AppBar, Toolbar, IconButton, useTheme, useMediaQuery } from '@mui/material';
import Navbar from '../components/Navbar';
import Ayuda from '../components/Ayuda';
import AppDrawer from '../components/AppDrawer';

const AyudaPage = () => {
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    photoURL: ''
  });

  const [loading, setLoading] = useState(true);
  const [isDrawerRight, setIsDrawerRight] = useState(false);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const auth = getAuth(app);
    const db = getFirestore(app);

    const fetchProfileData = async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data().info;
            setProfile({
              firstName: userData.firstName,
              lastName: userData.lastName,
              photoURL: userData.photoURL || '',
            });
          } else {
            console.log("No user document found");
          }
        } catch (error) {
          console.error("Error fetching user data from Firestore: ", error);
        }
      } else {
        console.log('No user is signed in.');
      }
      setLoading(false);
    };

    onAuthStateChanged(auth, (user) => {
      fetchProfileData(user);
    });
  }, []);

  const toggleDrawerPosition = () => {
    setIsDrawerRight(!isDrawerRight);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
      <AppBar 
        position="static" 
        elevation={0} 
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar disableGutters sx={{ bgcolor: "#fff" }}>
          <Navbar profile={profile} />
          <IconButton onClick={toggleDrawerPosition}>
            {isDrawerRight ? 'Left' : 'Right'}
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
        {!isDrawerRight && !isMobile && (
          <AppDrawer isRight={isDrawerRight} />
        )}

        <Box sx={{ 
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          overflow: 'auto',
          p: isMobile ? 0 : 2,
          mt: 3,
          background: 'linear-gradient(to bottom, #ffffff, #e0e8ef)', 
        }}>
          {isMobile ? (
            <Box sx={{ width: '100%' }}>
              <Ayuda />
            </Box>
          ) : (
            <Ayuda />
          )}
        </Box>

        {isDrawerRight && !isMobile && (
          <AppDrawer isRight={isDrawerRight} />
        )}
      </Box>
    </Box>
  );
};

export default AyudaPage;