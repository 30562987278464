// PictureInPictureTip.js

import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PictureInPictureTip = () => {
    const [showTip, setShowTip] = useState(true);

  const handleCloseTip = () => {
    setShowTip(false);
    localStorage.setItem('showPictureInPictureTip', 'false');
  };

  if (!showTip) return null;

  return (
    <Card
      sx={{
        position: 'absolute',
        top: 20,
        right: 20,
        width: 320,
        zIndex: 1000,
        backgroundColor: '#f0f0f0',
        borderRadius: 5,
        border: '1px solid #e0e0e0',
        boxShadow: 'none',
        transition: 'background-color 0.2s, border 0.2s, box-shadow 0.2s',
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: 'rgba(240, 240, 240, 0.95)', // Slightly transparent on hover
          borderColor: '#1976d2',
          boxShadow: '0 0 5px #1976d2',
        },
        '&:active': {
          backgroundColor: '#f0f0f0',
        },
      }}
    >
      <CardContent sx={{ position: 'relative' }}>
        <IconButton
          aria-label="close"
          onClick={handleCloseTip}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="div" gutterBottom>
          Tip maestro #1
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Recuerda que puedes usar el picture-in-picture para ver a tu compañero
          mientras navegas en otra ventana. Haz click en los tres puntos (...)
          de su video y luego en{' '}
          <Typography component="span" variant="body2" fontWeight="bold">
            'Enter picture-in-picture'
          </Typography>
          .
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PictureInPictureTip;

