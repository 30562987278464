import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const Root = styled(Box)(({ theme }) => ({
  // Add bottom margin if element below
  "&:not(:last-child)": {
    marginBottom: "2rem",
  },
}));

const Subtitle = styled(Typography)({
  // Subtitle text generally isn't very long
  // so usually looks better to limit width.
  maxWidth: 700,
  // So we can have max-width but still
  // have alignment controlled by text-align.
  display: "inline-block",
});

function SectionHeader(props) {
  const { subtitle, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Root
      component="header"
      className={className}
      {...otherProps}
    >
      {title && (
        <Typography
          variant={`h${size}`}
          gutterBottom={subtitle ? true : false}
        >
          {title}
        </Typography>
      )}

      {subtitle && (
        <Subtitle variant="subtitle1">
          {subtitle}
        </Subtitle>
      )}
    </Root>
  );
}

export default SectionHeader;