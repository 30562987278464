import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Navbar from "../components/Navbar";
import PricingSection from "../components/PricingSection";
import stickerImage from "../assets/images/50.png";

function PricingPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        background: "linear-gradient(to bottom, #ffffff, #f5f9fc)",
      }}
    >
      <Navbar />
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            paddingTop: "40px",
          }}
        >
          <PricingSection
            bgColor="default"
            size="medium"
            bgImage=""
            bgImageOpacity={1}
            title="SessionFocus Premium"
            subtitle="Suscríbete a SessionFocus Premium para participar en todas las sesiones de trabajo que quieras"
            stickerSrc={stickerImage}
          />
        </Box>
      </Box>
      <Box>
      </Box>
    </Box>
  );
}

export default PricingPage;