import * as React from 'react';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularLogo from '../assets/images/circularlogo.png';
import GoogleIcon from '@mui/icons-material/Google';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import handleSignUpWithEmail from './handleSignUpWithEmail';
import handleGoogleSignUp from './handleGoogleSignUp';
import RoleDialog from './RoleDialog';
import { getFirestore, setDoc, doc, serverTimestamp } from "firebase/firestore";
import { app } from '../firebase';

function Footer(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      <Link color="inherit" href="/" sx={{ textDecoration: 'underline' }}>
        SessionFocus,
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignUp() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    form: ''
  });
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleRoleDialogClose = () => {
    setRoleDialogOpen(false);
  };

  const handleRoleSave = async (role) => {
    if (userDetails) {
      const { user, firstName, lastName, username, email } = userDetails;
      const db = getFirestore(app);

      await setDoc(doc(db, "users", user.uid), {
        info: {
          firstName: firstName,
          lastName: lastName,
          email: email || user.email,
          username: username,
          role: role,
          type: "regular",
          createdAt: serverTimestamp(),
          firstTime: true
        }
      });

      navigate('/homepage');
    }
  };

  const validate = (data) => {
    let tempErrors = { 
      firstName: '', 
      lastName: '', 
      email: '', 
      password: '',
      confirmPassword: '',
      form: ''
    };
    tempErrors.firstName = data.get('firstName') ? '' : 'First Name is required.';
    tempErrors.lastName = data.get('lastName') ? '' : 'Last Name is required.';
    tempErrors.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(data.get('email')) ? '' : 'Email is not valid.';
    tempErrors.password = data.get('password').length >= 7 ? '' : 'Password must be at least 7 characters.';
    tempErrors.confirmPassword = data.get('password') === data.get('confirmPassword') ? '' : 'Passwords do not match.';
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === '');
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (validate(data)) {
      setIsLoading(true);
      try {
        const user = await handleSignUpWithEmail(event);
        setUserDetails({
          user,
          firstName: data.get('firstName'),
          lastName: data.get('lastName'),
          email: data.get('email'),
          username: `${data.get('firstName')} ${data.get('lastName')}`
        });
        setRoleDialogOpen(true);
      } catch (error) {
        console.error('Error during sign up:', error);
        if (error.code === 'auth/email-already-in-use') {
          setErrors(prev => ({ ...prev, form: 'Ya existe una cuenta con este email. Intenta iniciar sesión.' }));
        } else {
          setErrors(prev => ({ ...prev, form: 'An error occurred during sign up. Please try again.' }));
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onGoogleSignUp = async () => {
    setIsLoading(true);
    try {
      const { user, firstName, lastName, username, isNewUser } = await handleGoogleSignUp();
      if (isNewUser) {
        setUserDetails({ user, firstName, lastName, username });
        setRoleDialogOpen(true);
      } else {
        navigate('/homepage');
      }
    } catch (error) {
      console.error('Error during Google sign up:', error);
      setErrors(prev => ({ ...prev, form: 'Ha ocurrido un error durante el registro. Por favor intenta de nuevo.' }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <img src={CircularLogo} alt="logo" style={{ width: '100%', height: '100%' }} />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
            Crea una cuenta de SessionFocus
          </Typography>
          <Box sx={{ mt: 3, width: '100%' }}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={onGoogleSignUp}
              sx={{ mb: 2 }}
              disabled={isLoading}
            >
              {isLoading ? 'Registrando...' : 'Regístrate con Google'}
            </Button>
            <Divider sx={{ my: 2 }}>
              <Typography color="text.secondary" variant="body2">
                o
              </Typography>
            </Divider>
            <Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 3, textAlign: 'center' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="Nombre"
                    autoFocus
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Apellido"
                    name="lastName"
                    autoComplete="family-name"
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirmar Contraseña"
                    type="password"
                    id="confirmPassword"
                    autoComplete="new-password"
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword}
                  />
                </Grid>
              </Grid>
              {errors.form && (
                <Typography color="error" align="center" sx={{ mt: 2 }}>
                  {errors.form}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading}
              >
                {isLoading ? 'Registrando...' : 'Registrarme'}
              </Button>
              <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
                Al registrarte, aceptas nuestros{' '}
                <Link href="#" color="primary">
                  términos de uso
                </Link>{' '}
                y{' '}
                <Link href="#" color="primary">
                  política de privacidad
                </Link>
                .
              </Typography>
              <Typography align="center" sx={{ mt: 2 }}>
                <Link href="/login" variant="body2">
                  ¿Ya tienes una cuenta? Inicia sesión
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Footer sx={{ mt: 5 }} />
      </Container>
      <RoleDialog
        open={roleDialogOpen}
        handleClose={handleRoleDialogClose}
        handleSave={handleRoleSave}
      />
    </ThemeProvider>
  );
}