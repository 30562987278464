import React, { useRef } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { app } from '../firebase';

// Import audio files
import finalAudio from '../assets/audios/exito.mp3';
import startAudio from '../assets/audios/start.mp3';
import fantasiaAudio from '../assets/audios/fantasia.mp3';
import arpaAudio from '../assets/audios/arpa.mp3';
import nivelCompletadoAudio from '../assets/audios/nivelcompletado.mp3';
import gameOverAudio from '../assets/audios/gameover.mp3';
import notificacionAudio from '../assets/audios/notificacion.mp3';
import pianoAudio from '../assets/audios/piano.mp3';

const audioOptions = [
  { label: 'Inicio', value: 'start', audio: startAudio, volume: 0.7 },
  { label: 'Fantasía', value: 'fantasia', audio: fantasiaAudio, volume: 1.0 },
  { label: 'Notificación', value: 'notificacion', audio: notificacionAudio, volume: 0.7 },
  { label: 'Piano', value: 'piano', audio: pianoAudio, volume: 0.7 },
  { label: 'Arpa', value: 'arpa', audio: arpaAudio, volume: 1.0 },
  { label: 'Nivel Completado', value: 'nivelcompletado', audio: nivelCompletadoAudio, volume: 1.0 },
  { label: 'Nivel Completado II', value: 'exito', audio: finalAudio, volume: 0.7 },
  { label: 'Game Over', value: 'gameover', audio: gameOverAudio, volume: 0.7 },
];

const AudioSelector = ({ selectedAudio, setSelectedAudio, userId }) => {
  const audioRef = useRef(null);
  const db = getFirestore(app);

  const playAudio = (audioValue) => {
    // Stop previous audio if it exists
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    const selectedOption = audioOptions.find(option => option.value === audioValue);
    if (selectedOption) {
      const newAudio = new Audio(selectedOption.audio);
      newAudio.volume = selectedOption.volume;
      newAudio.play().catch(error => {
        console.error('Error playing audio:', error);
      });
      audioRef.current = newAudio;
    }
  };

  const handleAudioChange = async (event) => {
    const newValue = event.target.value;
    setSelectedAudio(newValue);
    
    // Play audio only when user explicitly selects it
    playAudio(newValue);

    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        // Update existing info document
        await setDoc(userRef, {
          info: {
            ...userDoc.data().info,
            audioPreference: newValue
          }
        }, { merge: true });
      } else {
        // Create new info document
        await setDoc(userRef, {
          info: {
            audioPreference: newValue
          }
        });
      }
    } catch (error) {
      console.error('Error saving audio preference:', error);
    }
  };

  // Cleanup audio on unmount
  React.useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
        Audio de final de sesión
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Elige el audio que quieres que se reproduzca al final de la sesión
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="audio-select-label">Selecciona un audio</InputLabel>
        <Select
          labelId="audio-select-label"
          id="audio-select"
          value={selectedAudio}
          label="Selecciona un audio"
          onChange={handleAudioChange}
        >
          {audioOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default AudioSelector;