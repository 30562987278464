// src/hooks/useAudio.js

import { useRef, useEffect } from 'react';

const useAudio = (audioFile, volume = 0.35) => {
  const audioRef = useRef(null);
  const hasPlayedAutomatically = useRef(false);

  useEffect(() => {
    audioRef.current = new Audio(audioFile);
    audioRef.current.volume = volume;

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, [audioFile, volume]);

  const playAudio = () => {
    if (audioRef.current && !hasPlayedAutomatically.current) {
      audioRef.current.play().catch((error) => {
        console.error('Error playing audio:', error);
      });
      hasPlayedAutomatically.current = true;
    }
  };

  return { playAudio };
};

export default useAudio;
