// usePresenceTracking.js

import { useEffect, useRef } from 'react';
import { ref, set, onDisconnect, off } from 'firebase/database';
import { realtimeDb } from '../firebase';

const usePresenceTracking = (sessionId, roomId, userId) => {
  const hasLeftRef = useRef(false);

  useEffect(() => {
    if (!sessionId || !roomId || !userId) return;

    const userPresenceRef = ref(
      realtimeDb,
      `sessions/${sessionId}/rooms/${roomId}/users/${userId}/presence`
    );

    const setupPresence = async () => {
      try {
        // Set initial presence to true
        await set(userPresenceRef, true);

        // Set up onDisconnect to set presence to false when the user disconnects
        await onDisconnect(userPresenceRef).set(false);
      } catch (error) {
        console.error('Error setting up presence:', error);
      }
    };

    setupPresence();

    return () => {
      if (!hasLeftRef.current) {
        hasLeftRef.current = true;

        // Remove user presence by setting it to false
        set(userPresenceRef, false).catch((error) =>
          console.error('Error setting presence to false:', error)
        );

        // Cancel the onDisconnect operation
        onDisconnect(userPresenceRef)
          .cancel()
          .catch((error) => console.error('Error cancelling onDisconnect:', error));
      }
    };
  }, [sessionId, roomId, userId]);

  return {
    leaveRoom: () => {
      if (!hasLeftRef.current) {
        hasLeftRef.current = true;

        const userPresenceRef = ref(
          realtimeDb,
          `sessions/${sessionId}/rooms/${roomId}/users/${userId}/presence`
        );

        // Remove user presence by setting it to false
        set(userPresenceRef, false).catch((error) =>
          console.error('Error setting presence to false:', error)
        );

        // Cancel the onDisconnect operation
        onDisconnect(userPresenceRef)
          .cancel()
          .catch((error) => console.error('Error cancelling onDisconnect:', error));
      }
    },
  };
};

export default usePresenceTracking;
