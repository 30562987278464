import React, { useState, useEffect } from "react";
import { ThemeProvider } from '@mui/material/styles';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { app } from '../firebase';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Navbar from "../components/Navbar";
import theme from "../theme";
import AppDrawer from "../components/AppDrawer";
import NextEvent from "../components/NextEvent";
import FirstTimeUserCard from "../components/FirstTimeUserCard";

import backgroundImage from '../assets/images/fondo2.png';

function HomePage({ backgroundDarkness = 0.6 }) {
  const [loading, setLoading] = useState(false);
  const [isDrawerRight, setIsDrawerRight] = useState(false);
  const [showFirstTimeCard, setShowFirstTimeCard] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth(app);
      const db = getFirestore(app);
      const user = auth.currentUser;

      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.info && userData.info.firstTime) {
            setShowFirstTimeCard(true);
          }
        }
      }
    };

    fetchUserData();
  }, []);

  const handleCloseFirstTimeCard = async () => {
    const auth = getAuth(app);
    const db = getFirestore(app);
    const user = auth.currentUser;

    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        'info.firstTime': false,
      });
    }

    setShowFirstTimeCard(false);
  };

  const handleUnregister = () => {
    console.log("Unregister clicked");
  };

  const toggleDrawerPosition = () => {
    setIsDrawerRight(!isDrawerRight);
  };

  const commonCardStyle = {
    backgroundColor: '#f5f5f5',
    borderRadius: 4,
    border: '1px solid #e0e0e0',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    transition: 'all 0.3s ease',
    '&:hover, &.Mui-focusVisible': {
      boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
      backgroundColor: '#f5f5f5',
      borderColor: '#0050ef',
    },
    '&:active': {
      backgroundColor: '#e0e0e0',
    },
  };

  // Adjusted the marginRight to apply only on medium screens and above
  const eventsCardStyle = {
    ...commonCardStyle,
    display: 'flex',
    flexDirection: 'column',
    marginRight: { md: 6, xs: 0 },
  };

  const howItWorksCardStyle = {
    ...commonCardStyle,
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        height: '100vh',
        overflow: 'hidden',
      }}>
        <AppBar 
          position="fixed" 
          elevation={0} 
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar disableGutters sx={{ bgcolor: "#fff" }}>
            <Navbar /> 
            <IconButton onClick={toggleDrawerPosition}>
              {isDrawerRight ? 'Left' : 'Right'}
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box sx={{ 
          display: 'flex', 
          flexGrow: 1, 
          overflow: 'hidden',
          paddingTop: { xs: '56px', sm: '64px' }, // Account for AppBar height
        }}>
          {!isDrawerRight && (
            <AppDrawer isRight={isDrawerRight} />
          )}

          <Box sx={{ 
            flexGrow: 1,
            overflow: 'auto',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}>
            <Box sx={{
              minHeight: '100%',
              width: '100%',
              padding: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              backdropFilter: `brightness(${backgroundDarkness})`,
            }}>
              <Grid 
                container 
                spacing={6} 
                justifyContent="center" 
                alignItems="flex-start" 
                sx={{ 
                  maxWidth: '1200px',
                  paddingTop: { xs: 2, sm: 4, md: 6 },
                }}
              >
                <Grid item xs={12} md={5}>
                  <Card sx={eventsCardStyle}>
                    <CardContent sx={{ pb: 0 }}>
                      <NextEvent />
                    </CardContent>
                    <Divider  />
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                        sx={{ mt: 2 }}
                      >
                        Revisa todas las sesiones disponibles en el{' '}
                        <a href="/calendar" style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
                          calendario
                        </a>.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Card sx={howItWorksCardStyle}>
                    <CardContent>
                      <Typography
                        variant="h5"
                        gutterBottom
                        align="center"
                        sx={{ fontFamily: 'Raleway, sans-serif', fontWeight: 'bold' }}
                      >
                        Cómo funciona
                      </Typography>
                      <Typography 
                        variant="body1"
                        color="text.secondary" 
                        align="center" 
                        sx={{ mt: 2 }}
                      >
                        1. Cuando sea la hora de la sesión, haz click en "Entrar". <strong>Las sesiones cierran a los 10 minutos de empezar.</strong>  <br />
                        2. Dentro de la sesión, saluda a tu compañero y explica en qué vas a trabajar. <br />
                        3. Al final de la sesión, comenta cómo te fue en la sesión y despídete. <br /> <br />
                        <strong>Hay una sesión nueva cada hora desde las 16:00 hasta las 22:00. ¡Recuerda ser puntual para poder entrar a la sesión!</strong>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {isDrawerRight && (
            <AppDrawer isRight={isDrawerRight} />
          )}
        </Box>
        <FirstTimeUserCard open={showFirstTimeCard} onClose={handleCloseFirstTimeCard} />
      </Box>
    </ThemeProvider>
  );
}

export default HomePage;

