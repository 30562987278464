import { useState } from 'react';
import { deleteDoc, doc, getDocs, collection } from 'firebase/firestore';

const useDeleteSession = (db) => {
  const [deleteStatus, setDeleteStatus] = useState({ loading: false, error: null });

  const deleteSession = async (sessionId) => {
    setDeleteStatus({ loading: true, error: null });

    try {
      // Delete the session from the "sessions" collection
      // await deleteDoc(doc(db, 'sessions', sessionId));

      // Get all user documents
      const usersSnapshot = await getDocs(collection(db, 'users'));

      // Iterate through each user document
      const deletePromises = usersSnapshot.docs.map(async (userDoc) => {
        const userId = userDoc.id;
        const registeredSessionsRef = collection(db, 'users', userId, 'registeredSessions');

        // Check if the user has the session in their "registeredSessions" subcollection
        const registeredSessionsSnapshot = await getDocs(registeredSessionsRef);
        const sessionToDelete = registeredSessionsSnapshot.docs.find(
          (doc) => doc.data().sessionId === sessionId
        );

        if (sessionToDelete) {
          // Delete the session from the user's "registeredSessions" subcollection
          await deleteDoc(doc(registeredSessionsRef, sessionToDelete.id));
        }
      });

      // Wait for all deletions to complete
      await Promise.all(deletePromises);

      setDeleteStatus({ loading: false, error: null });
    } catch (error) {
      console.error('Error borrando sesión:', error);
      setDeleteStatus({ loading: false, error: error.message });
    }
  };

  return { deleteSession, deleteStatus };
};

export default useDeleteSession;