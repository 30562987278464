// src/hooks/useFetchCustomSessions.js

import { useEffect, useState } from 'react';
import { ref, query, orderByChild, startAt, onValue } from 'firebase/database';
import { realtimeDb } from '../firebase'; // Import Realtime Database

const useFetchCustomSessions = (currentTime) => {
  const [firebaseSessions, setFirebaseSessions] = useState([]);

  useEffect(() => {
    const fetchSessions = () => {
      // Set a threshold time of 60 minutes before the current time
      const thresholdTime = new Date(currentTime.getTime() - 60 * 60000); // 60 minutes in the past
      const thresholdIsoString = thresholdTime.toISOString(); // Convert to ISO String for comparison

      // Create a reference to the Realtime Database sessions path
      const sessionsRef = ref(realtimeDb, 'sessions');
      
      // Query sessions where startDate is greater than the threshold time and order by startDate
      const querySessions = query(
        sessionsRef,
        orderByChild('startDate'), // Order by startDate
      );

      // Listen for real-time updates
      const unsubscribe = onValue(querySessions, (snapshot) => {
        const fetchedSessions = [];

        snapshot.forEach((childSnapshot) => {
          const sessionData = childSnapshot.val();

          // Convert ISO strings back to JavaScript Date objects
          const startDate = new Date(sessionData.startDate);
          const endDate = new Date(sessionData.endDate);

          fetchedSessions.push({
            id: childSnapshot.key, // Use Realtime Database's unique key as ID
            title: sessionData.title || 'Sin título',
            startDate: startDate,
            endDate: endDate,
          });
        });

        setFirebaseSessions(fetchedSessions);
      });

      return unsubscribe;
    };

    const unsubscribe = fetchSessions();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [currentTime]);

  return firebaseSessions;
};

export default useFetchCustomSessions;
