import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid, Card, CardContent, CardActionArea } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';  // Make sure this path is correct
import estudiante from '../assets/images/estudiante.png';  // Correct path to the estudiante logo
import trabajador from '../assets/images/trabajador.png';  // Correct path to the trabajador logo

const RoleDialog = ({ open, handleClose, handleSave }) => {
  const [role, setRole] = useState('worker');
  const [isSaving, setIsSaving] = useState(false);

  const handleRoleChange = (role) => {
    setRole(role);
  };

  const handleSaveRole = async () => {
    setIsSaving(true);
    try {
      await handleSave(role);
    } catch (error) {
      console.error("Error saving role:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <Box sx={{ borderRadius: 4, overflow: 'hidden' }}>
          <DialogTitle>
            <Typography variant="h5" component="div" align="center">
              Solo una pregunta más antes de comenzar,<br /> ¿eres un estudiante o un trabajador?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box mb={2} />
            <Grid container spacing={4} justifyContent="center" alignItems="center" style={{ minHeight: '320px' }}>
              <Grid item>
                <CardActionArea onClick={() => handleRoleChange('student')} disabled={isSaving}>
                  <Card 
                    variant="outlined" 
                    sx={{ 
                      textAlign: 'center', 
                      border: role === 'student' ? '2px solid blue' : '1px solid gray', 
                      borderRadius: 2, 
                      height: '280px',
                      width: '220px',
                      backgroundColor: role === 'student' ? 'rgba(0, 0, 255, 0.05)' : 'inherit',
                    }}>
                    <CardContent sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      justifyContent: 'flex-start', 
                      height: '100%', 
                      paddingTop: '16px'
                    }}>
                      <img src={estudiante} alt="Student" style={{ width: 160, height: 160, borderRadius: '50%', marginBottom: 16 }} />
                      <Typography variant="h5" fontWeight="bold">Estudiante</Typography>
                    </CardContent>
                  </Card>
                </CardActionArea>
              </Grid>
              <Grid item>
                <CardActionArea onClick={() => handleRoleChange('worker')} disabled={isSaving}>
                  <Card 
                    variant="outlined" 
                    sx={{ 
                      textAlign: 'center', 
                      border: role === 'worker' ? '2px solid blue' : '1px solid gray', 
                      borderRadius: 2, 
                      height: '280px',
                      width: '220px',
                      backgroundColor: role === 'worker' ? 'rgba(0, 0, 255, 0.05)' : 'inherit',
                    }}>
                    <CardContent sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      justifyContent: 'flex-start', 
                      height: '100%', 
                      paddingTop: '16px'
                    }}>
                      <img src={trabajador} alt="Worker" style={{ width: 160, height: 160, borderRadius: '50%', marginBottom: 16 }} />
                      <Typography variant="h5" fontWeight="bold">Trabajador</Typography>
                    </CardContent>
                  </Card>
                </CardActionArea>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleSaveRole} 
              variant="contained" 
              color="primary"
              disabled={isSaving}
            >
              {isSaving ? 'Guardando...' : 'Guardar'}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

export default RoleDialog;