// src/components/Calendar.js

import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  WeekView,
  Appointments,
  Toolbar as SchedulerToolbar,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
  CurrentTimeIndicator,
} from '@devexpress/dx-react-scheduler-material-ui';
import { styled, alpha } from '@mui/material/styles';
import useFetchCustomSessions from '../hooks/useFetchCustomSessions';
import CustomTooltipContent from './CustomTooltipContent';

const PREFIX = 'Calendar';
const classes = {
  shadedCell: `${PREFIX}-shadedCell`,
};

const StyledWeekViewTimeTableCell = styled(WeekView.TimeTableCell)(({ theme }) => ({
  [`&.${classes.shadedCell}`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  },
}));

const TimeTableCell = ({ startDate, endDate, ...restProps }) => {
  const now = new Date();
  const isPassedDate = now > endDate;
  return <StyledWeekViewTimeTableCell {...restProps} className={isPassedDate ? classes.shadedCell : ''} />;
};

const Appointment = ({ children, style, ...restProps }) => {
  const { data } = { ...restProps };
  const now = new Date();
  const startDate = new Date(data.startDate);
  const endDate = new Date(data.endDate);

  const isPastEvent = now > endDate;
  const isOngoingEvent = now >= startDate && now <= endDate;
  const isRecentlyStarted = now >= startDate && (now - startDate <= 50 * 60 * 1000); // 50 minutes in milliseconds

  const shouldShade = isPastEvent || isOngoingEvent || isRecentlyStarted;

  return (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        backgroundColor: data.color,
        borderRadius: '8px',
        opacity: shouldShade ? 0.5 : 1,
      }}
    >
      {children}
    </Appointments.Appointment>
  );
};

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [currentViewName, setCurrentViewName] = useState('Week');

  const firebaseSessions = useFetchCustomSessions(currentDate);

  useEffect(() => {
    const uniqueSessions = firebaseSessions.map((session) => ({
      ...session,
      color: '#2196f3',
    }));

    setEvents(uniqueSessions);
  }, [currentDate, firebaseSessions]);

  useEffect(() => {
    const updateView = () => {
      if (window.innerWidth <= 768) {
        setCurrentViewName('Day');
      } else {
        setCurrentViewName('Week');
      }
    };

    updateView();
    window.addEventListener('resize', updateView);
    return () => window.removeEventListener('resize', updateView);
  }, []);

  return (
    <Paper
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        borderRadius: '16px',
      }}
    >
      <Scheduler data={events} height="100%" firstDayOfWeek={1} locale={'es-ES'}>
        <ViewState
          currentDate={currentDate}
          onCurrentDateChange={setCurrentDate}
          currentViewName={currentViewName}
        />
        <DayView
          name="Day"
          startDayHour={15}
          endDayHour={23}
          cellDuration={30}
          timeTableCellComponent={TimeTableCell}
        />
        <WeekView
          name="Week"
          startDayHour={15}
          endDayHour={23}
          cellDuration={30}
          timeTableCellComponent={TimeTableCell}
        />
        <SchedulerToolbar />
        <DateNavigator />
        <TodayButton messages={{ today: 'Hoy' }} />
        <Appointments appointmentComponent={Appointment} />
        <CurrentTimeIndicator updateInterval={60000} shadePreviousCells shadePreviousAppointments />
        <AppointmentTooltip
          contentComponent={(props) => (
            <CustomTooltipContent
              {...props}
              appointmentData={props.appointmentData}
              onDelete={(id) => setEvents(events.filter((event) => event.id !== id))}
            />
          )}
        />
      </Scheduler>
    </Paper>
  );
};

export default Calendar;
