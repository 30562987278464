import React, { useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Ayuda = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Paper
      sx={{
        p: 3,
        borderRadius: 4,
        boxShadow: 3,
        mb: 3,
        maxWidth: '600px',
        width: '100%',
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ mb: 5 }}
      >
        Cómo funciona
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom align="center">
          Entrar a las sesiones
        </Typography>
        <Typography variant="body1" gutterBottom>
          Las sesiones están abiertas para entrar durante sus primeros 10 minutos. Por ejemplo, para
          la sesión de las 16:00 se puede entrar hasta las 16:10. Después de eso, la sesión se
          cierra para no interrumpir a las personas que están trabajando. No es necesario registrarse para entrar, llegas y entras
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom align="center">
          Dentro de la sesión
        </Typography>
        <Typography variant="body1" gutterBottom>
          Saludas a tu compañero, explicas brevemente en qué vas a trabajar, y luego trabajas en lo que quieras durante los 50 minutos de la sesión, idealmente en mute. ¡Recuerda tener la cámara prendida para que tu compañero pueda verte!
        </Typography>
      </Box>

      <Box sx={{ mb: 6 }}>
        <Typography variant="h6" gutterBottom align="center">
          Al final de la sesión
        </Typography>
        <Typography variant="body1" gutterBottom>
          Comentas qué lograste hacer en la sesión, y te despides. ¡Listo!
        </Typography>
      </Box>

      <Typography
        component="h2"
        variant="h5"
        sx={{ mt: 5, mb: 3 }}
        align="center"
      >
        Preguntas frecuentes
      </Typography>
      <Box sx={{ width: '100%', mb: 4 }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography
              component="h3"
              variant="subtitle2"
              sx={{ fontWeight: 'bold' }}
            >
              ¿Cómo puedo entrar a una sesión?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom>
              Es muy fácil, eliges en el calendario el horario que te acomode, te registras para
              ella, y luego cuando sea la hora de la sesión, verás un link para entrar a ella.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography
              component="h3"
              variant="subtitle2"
              sx={{ fontWeight: 'bold' }}
            >
              ¿Las sesiones son gratis?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom>Sí!</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography
              component="h3"
              variant="subtitle2"
              sx={{ fontWeight: 'bold' }}
            >
              ¿Qué tengo que hacer en cada sesión?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom>
              Primero, saludas a tu compañero de sesión y se explican qué es lo que planean hacer
              durante la sesión. Luego, apagan el micrófono y trabajan de manera silenciosa durante
              los 50 minutos. Finalmente, cuando se acabe la sesión, comentan lo que hicieron durante
              ella y se despiden.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography
              component="h3"
              variant="subtitle2"
              sx={{ fontWeight: 'bold' }}
            >
              ¿Qué tipo de trabajo puedo hacer durante la sesión?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom>
              ¡El que quieras! Puedes estudiar, trabajar desde casa, organizar tu día, diseñar,
              escribir, lo que sea mientras estés concentrado frente a la cámara.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <Typography
              component="h3"
              variant="subtitle2"
              sx={{ fontWeight: 'bold' }}
            >
              ¿Por qué participar de una sesión en vez de trabajar solo?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom>
              Porque cuando tienes una persona enfrente tuyo trabajando, también sientes el impulso
              de trabajar. Ese es el espíritu de las bibliotecas y áreas de trabajo. Además, tener
              una persona al otro lado de la pantalla contando contigo es un estímulo para no
              abandonar la sesión ni dejar tu lugar de trabajo.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Paper>
  );
};

export default Ayuda;