import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Navbar from "../components/Navbar";
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from "../components/AuthProvider";

function PaymentSuccessPage() {
  const { user, updateUserType } = useAuth();
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const updateUserToPremium = async () => {
      if (user) {
        try {
          await updateUserType("premium");
          console.log("User updated to premium successfully");
        } catch (error) {
          console.error("Error updating user to premium:", error);
          setError("Failed to update to premium. Please contact support.");
        } finally {
          setIsUpdating(false);
        }
      } else {
        console.error("No user found");
        navigate("/login");
      }
    };

    updateUserToPremium();
  }, [user, updateUserType, navigate]);

  if (isUpdating) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Navbar />
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center', 
          minHeight: '100vh', 
          paddingTop: '40px', 
          paddingBottom: '32px', 
          background: 'linear-gradient(to bottom, #ffffff, #f5f9fc)',
          textAlign: 'center'
        }}
      >
        {error ? (
          <>
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '16px', color: 'error.main' }}>
              Error
            </Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: '24px' }}>
              {error}
            </Typography>
          </>
        ) : (
          <>
            <CheckCircleOutlineIcon sx={{ fontSize: 60, color: 'green', marginBottom: '8px' }} />
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
              ¡Felicidades! Ahora eres parte de SessionFocus Premium. 
            </Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: '24px' }}>
              Puedes participar en todas las sesiones que quieras. <br /> 
              Si tienes problemas con el pago y la suscripción, escribe a <strong>contacto@sessionfocus.com</strong> y lo solucionamos inmediatamente.
            </Typography>
          </>
        )}
        <Button variant="contained" color="primary" component={Link} to="/">
          Volver al inicio
        </Button>
      </Box>
    </>
  );
}

export default PaymentSuccessPage;