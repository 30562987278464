import React, { useState } from 'react';
import { Box, Button, Paper } from '@mui/material';
import AddEventModal from './AddEventModal';
import AddEventPackModal from './AddEventPackModal';
import SidebarNextEvent from './SidebarNextEvent';
import NextEvent from "../components/NextEvent";
import { useAuth } from '../components/AuthProvider';

const Sidebar = () => {
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isEventPackModalOpen, setIsEventPackModalOpen] = useState(false);
  const { user } = useAuth();

  const handleOpenEventModal = () => {
    setIsEventModalOpen(true);
  };

  const handleCloseEventModal = () => {
    setIsEventModalOpen(false);
  };

  const handleOpenEventPackModal = () => {
    setIsEventPackModalOpen(true);
  };

  const handleCloseEventPackModal = () => {
    setIsEventPackModalOpen(false);
  };

  return (
    <Paper sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column', 
      borderRadius: '16px', 
      overflow: 'hidden',
      boxShadow: 3
    }}>
      <Box sx={{ 
        width: '100%', 
        bgcolor: 'background.paper', 
        overflowY: 'auto', 
        flexGrow: 1, 
        borderRadius: '16px',
        pt: 2
      }}>
        <NextEvent />
        {user && user.type === 'admin' && (
          <Box sx={{ p: 2 }}>
            <Button 
              variant="contained" 
              color="primary"
              onClick={handleOpenEventModal}
              sx={{ mt: 2, mr: 2 }}
            >
              Add event
            </Button>
            <Button 
              variant="contained" 
              color="primary"
              onClick={handleOpenEventPackModal}
              sx={{ mt: 2 }}
            >
              Add session pack
            </Button>
          </Box>
        )}
        <AddEventModal isOpen={isEventModalOpen} closeModal={handleCloseEventModal} />
        <AddEventPackModal isOpen={isEventPackModalOpen} closeModal={handleCloseEventPackModal} />
      </Box>
    </Paper>
  );
};

export default Sidebar;