import React from 'react';
import { Paper, TextField, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import '@fontsource/raleway';

const ProfileDetails = ({ isEditing, editedProfile, handlePersonalInfoChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const getTitleVariant = () => {
    if (isMobile) return 'h6';
    if (isTablet) return 'h5';
    return 'h4';
  };

  const renderField = (label, name) => (
    <Box sx={{ mb: 2 }}>
      <Typography 
        variant="body1" 
        sx={{ 
          fontWeight: 'bold', 
          mb: 0.5,
          color: 'text.primary',
          fontFamily: 'Raleway, sans-serif'
        }}
      >
        {label}
      </Typography>
      <Typography variant="body1">
        {editedProfile.personalInfo[name] || ''}
      </Typography>
    </Box>
  );

  return (
    <Paper sx={{ p: { xs: 2, sm: 3 }, borderRadius: 4, boxShadow: 3, mb: 3 }}>
      <Typography 
        variant={getTitleVariant()} 
        align="center" 
        gutterBottom 
        sx={{ 
          mb: { xs: 2, sm: 3 },
          fontFamily: 'Raleway, sans-serif',
          fontWeight: 'bold'
        }}
      >
        Información personal
      </Typography>
      {isEditing ? (
        <>
          <TextField
            fullWidth
            label="En qué estoy trabajando"
            name="workingOn"
            value={editedProfile.personalInfo.workingOn || ''}
            onChange={(e) => handlePersonalInfoChange('workingOn', e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Mi forma favorita de perder el tiempo"
            name="favoriteTimeWaster"
            value={editedProfile.personalInfo.favoriteTimeWaster || ''}
            onChange={(e) => handlePersonalInfoChange('favoriteTimeWaster', e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Película favorita"
            name="favoriteMovie"
            value={editedProfile.personalInfo.favoriteMovie || ''}
            onChange={(e) => handlePersonalInfoChange('favoriteMovie', e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Libro favorito"
            name="favoriteBook"
            value={editedProfile.personalInfo.favoriteBook || ''}
            onChange={(e) => handlePersonalInfoChange('favoriteBook', e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Artista musical favorito"
            name="favoriteMusician"
            value={editedProfile.personalInfo.favoriteMusician || ''}
            onChange={(e) => handlePersonalInfoChange('favoriteMusician', e.target.value)}
            margin="normal"
          />
        </>
      ) : (
        <Box>
          {renderField("En qué estoy trabajando", "workingOn")}
          {renderField("Mi forma favorita de perder el tiempo", "favoriteTimeWaster")}
          {renderField("Película favorita", "favoriteMovie")}
          {renderField("Libro favorito", "favoriteBook")}
          {renderField("Artista musical favorito", "favoriteMusician")}
        </Box>
      )}
    </Paper>
  );
};

export default ProfileDetails;