import React, { useEffect } from "react";
import LegalSection from "../components/LegalSection";
import Divider from '@mui/material/Divider';
import Navbar from "../components/Navbar";
import Box from '@mui/material/Box';
import Footer from "../components/Footer";

function LegalPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar />
      <Box sx={{ flexGrow: 1, paddingTop: '40px' }}> {/* Added paddingBottom */}
        <LegalSection />
        <Divider />
        <Footer />
      </Box>
    </Box>
  );
}

export default LegalPage;
