import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, updateProfile as firebaseUpdateProfile } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { app } from '../firebase';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async (firebaseUser) => {
    const db = getFirestore(app);
    try {
      const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUser({
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          emailVerified: firebaseUser.emailVerified,
          ...userData.info,
          personalInfo: userData.personalInfo || {},
          displayName: firebaseUser.displayName || userData.info.username,
          photoURL: firebaseUser.photoURL || userData.info.photoURL,
        });
      } else {
        const newUserData = {
          info: {
            firstName: firebaseUser.displayName ? firebaseUser.displayName.split(' ')[0] : '',
            lastName: firebaseUser.displayName ? firebaseUser.displayName.split(' ').slice(1).join(' ') : '',
            email: firebaseUser.email,
            photoURL: firebaseUser.photoURL || '',
            type: 'normal',
            role: '',
            createdAt: new Date(),
            firstTime: true,
            username: firebaseUser.displayName || '',
          },
          personalInfo: {}
        };
        await setDoc(doc(db, 'users', firebaseUser.uid), newUserData);
        setUser({
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          emailVerified: firebaseUser.emailVerified,
          ...newUserData.info,
          personalInfo: newUserData.personalInfo,
          displayName: firebaseUser.displayName,
        });
      }
    } catch (error) {
      console.error('Error fetching user data: ', error);
      setUser(firebaseUser);
    }
  };

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        await fetchUserData(firebaseUser);
      } else {
        setUser(null);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const updateProfile = async (updatedProfile) => {
    const auth = getAuth(app);
    const user = auth.currentUser;
    if (user) {
      try {
        const db = getFirestore(app);
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        const currentUserData = userDoc.data();

        const updatedInfo = { ...currentUserData.info };
        if (updatedProfile.firstName) updatedInfo.firstName = updatedProfile.firstName;
        if (updatedProfile.lastName) updatedInfo.lastName = updatedProfile.lastName;
        if (updatedProfile.photoURL) updatedInfo.photoURL = updatedProfile.photoURL;
        if (updatedProfile.role) updatedInfo.role = updatedProfile.role;

        const updatedPersonalInfo = { ...currentUserData.personalInfo, ...updatedProfile.personalInfo };

        await updateDoc(userDocRef, { 
          info: updatedInfo,
          personalInfo: updatedPersonalInfo
        });

        await firebaseUpdateProfile(user, {
          displayName: updatedInfo.firstName && updatedInfo.lastName ? `${updatedInfo.firstName} ${updatedInfo.lastName}` : user.displayName,
          photoURL: updatedProfile.photoURL || user.photoURL,
        });

        await fetchUserData(auth.currentUser);
      } catch (error) {
        console.error("Error updating profile: ", error);
        throw error;
      }
    }
  };

  const updateUserType = async (newType) => {
    if (user) {
      try {
        const db = getFirestore(app);
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, { 'info.type': newType });
        await fetchUserData(getAuth(app).currentUser);
      } catch (error) {
        console.error("Error updating user type: ", error);
        throw error;
      }
    }
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, updateProfile, fetchUserData, updateUserType, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;