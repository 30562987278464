import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularLogo from '../assets/images/circularlogo.png';
import GoogleIcon from '@mui/icons-material/Google';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { app } from '../firebase';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import RoleDialog from './RoleDialog';
import handleGoogleSignUp from './handleGoogleSignUp';

function Footer(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      <Link color="inherit" href="/" sx={{ textDecoration: 'underline' }}>
        SessionFocus,
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const defaultTheme = createTheme();

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function LogIn() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);
  const [newUser, setNewUser] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    const auth = getAuth(app);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
    
      navigate('/homepage');
    } catch (error) {
      console.error('Error during email sign in:', error.code, error.message);
      setError('Incorrect email or password. Please try again.');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { user, isNewUser } = await handleGoogleSignUp();
      if (isNewUser) {
        setNewUser(user);
        setRoleDialogOpen(true);
      } else {
        navigate('/homepage');
      }
    } catch (error) {
      console.error('Error during Google sign in:', error.code, error.message);
      setError('Error during Google sign in. Please try again.');
    }
  };

  const handlePasswordReset = async (email) => {
    const auth = getAuth(app);
    try {
      await sendPasswordResetEmail(auth, email);
      setError('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError('Error sending password reset email. Please try again.');
    }
  };

  const handleRoleDialogClose = () => {
    setRoleDialogOpen(false);
  };

  const handleRoleSave = async (role) => {
    if (newUser) {
      const db = getFirestore(app);
      try {
        await updateDoc(doc(db, "users", newUser.uid), {
          'info.role': role
        });
        console.log('User role updated successfully');
        navigate('/homepage');
      } catch (error) {
        console.error('Error updating user role:', error);
        setError('Error updating user role. Please try again.');
      }
    }
    setRoleDialogOpen(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <img src={CircularLogo} alt="Circular Logo" style={{ width: '100%', height: '100%' }} />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
            Inicia sesión en SessionFocus
          </Typography>
          <Box sx={{ mt: 3, width: '100%' }}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              sx={{ mb: 2 }}
            >
              Inicia sesión con Google
            </Button>
            <Divider sx={{ my: 2 }}>
              <Typography color="text.secondary" variant="body2">
                o
              </Typography>
            </Divider>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Inicia Sesión
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={() => {
                    const email = prompt('Enter your email for password reset:');
                    if (email) handlePasswordReset(email);
                  }}>
                    ¿Olvidaste tu contraseña?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {"No tienes cuenta? Regístrate"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Footer sx={{ mt: 8, mb: 4 }} />
      </Container>
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={4000}
        onClose={() => setError('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setError('')} severity="info" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <RoleDialog
        open={roleDialogOpen}
        handleClose={handleRoleDialogClose}
        handleSave={handleRoleSave}
      />
    </ThemeProvider>
  );
}