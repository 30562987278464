import React, { useState, useEffect } from 'react';
import { Box, Typography, ThemeProvider } from '@mui/material';
import theme from '../theme';
import useAvailableEvents from '../hooks/useAvailableEvents';
import { getAuth } from 'firebase/auth';
import EventItem from './EventItem';
import '@fontsource/raleway';
import startAudio from '../assets/audios/start.mp3';
import useAudio from '../hooks/useAudio';
import useUserRegisteredSessions from '../hooks/useUserRegisteredSessions';

const NextEvent = () => {
  const [timeToSession, setTimeToSession] = useState('');
  const [canJoinSession, setCanJoinSession] = useState(false);
  const [eventToDisplay, setEventToDisplay] = useState(null);
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const { availableEvents } = useAvailableEvents();
  const userRegisteredSessions = useUserRegisteredSessions();
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const { playAudio } = useAudio(startAudio);

  const updateTimeToSession = (event, isUserRegistered) => {
    const now = new Date();
    const sessionTime = new Date(event.startDate);
    const sessionEndTime = new Date(event.endDate);
    const joinablePeriodStart = new Date(sessionTime.getTime() - 90 * 1000);
    const joinablePeriodEnd = isUserRegistered
      ? sessionEndTime
      : new Date(sessionTime.getTime() + 600 * 1000);

    // Calculate timeToSession
    if (now < sessionTime) {
      const timeDiff = sessionTime - now;
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      if (hours > 0) {
        setTimeToSession(`Comienza en ${hours} horas y ${minutes} minutos`);
      } else {
        setTimeToSession(
          `Comienza en: ${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`
        );
      }

      // Play audio if 30 seconds to session start
      if (minutes === 0 && seconds === 30) {
        playAudio();
      }
    } else if (now >= sessionTime && now <= sessionEndTime) {
      setTimeToSession('La sesión está en curso');
    } else {
      setTimeToSession('');
    }

    // Set canJoinSession
    if (now >= joinablePeriodStart && now <= joinablePeriodEnd) {
      setCanJoinSession(true);
    } else {
      setCanJoinSession(false);
    }
  };

  useEffect(() => {
    if (availableEvents.length > 0) {
      const now = new Date();

      let eventFound = null;
      let userIsRegistered = false;

      if (userRegisteredSessions.length > 0) {
        for (const userSession of userRegisteredSessions) {
          const session = availableEvents.find(
            (event) => event.id === userSession.sessionId
          );
          if (session) {
            if (now < new Date(session.endDate)) {
              eventFound = session;
              userIsRegistered = true;
              break;
            }
          }
        }
      }

      if (eventFound) {
        setIsUserRegistered(userIsRegistered);
        setEventToDisplay(eventFound);
      } else {
        const upcomingEvents = availableEvents.filter((event) => {
          const joinablePeriodEnd = new Date(event.startDate).getTime() + 600 * 1000;
          return now < new Date(joinablePeriodEnd);
        });

        if (upcomingEvents.length > 0) {
          eventFound = upcomingEvents[0];
          userIsRegistered = false;
          setIsUserRegistered(userIsRegistered);
          setEventToDisplay(eventFound);
        } else {
          setTimeToSession('');
          setCanJoinSession(false);
          setEventToDisplay(null);
          setIsUserRegistered(false);
        }
      }
    } else {
      setTimeToSession('');
      setCanJoinSession(false);
      setEventToDisplay(null);
      setIsUserRegistered(false);
    }
  }, [availableEvents, userRegisteredSessions]);

  useEffect(() => {
    let interval;

    if (eventToDisplay) {
      updateTimeToSession(eventToDisplay, isUserRegistered);

      interval = setInterval(() => {
        updateTimeToSession(eventToDisplay, isUserRegistered);
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [eventToDisplay, isUserRegistered]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 2, pt: 1 }}>
        <Box sx={{ mb: 2 }}>
          <Typography
            variant='h5'
            gutterBottom
            align='center'
            sx={{
              fontFamily: 'Raleway, sans-serif',
              fontWeight: 'bold',
              mt: 0,
            }}
          >
            Próxima sesión disponible
          </Typography>
          {eventToDisplay ? (
            <>
              <Typography
                variant='h6'
                color='text.secondary'
                gutterBottom
                align='center'
              >
                {timeToSession}
              </Typography>
              {canJoinSession && (
                <Typography
                  variant='h6'
                  color='text.secondary'
                  gutterBottom
                  align='center'
                  sx={{ fontWeight: 'bold', mt: 0 }}
                >
                  Puedes entrar a la sesión
                </Typography>
              )}
              <Box sx={{ position: 'relative' }}>
                <EventItem
                  event={eventToDisplay}
                  canJoinSession={canJoinSession}
                  isLoading={false}
                  sx={{ bgcolor: 'transparent' }}
                />
              </Box>
            </>
          ) : (
            <Typography
              variant='body2'
              color='text.secondary'
              gutterBottom
              align='center'
              sx={{ mt: 2 }}
            >
              No hay sesiones disponibles en este momento.
            </Typography>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default NextEvent;
