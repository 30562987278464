import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Import the Raleway font
import '@fontsource/raleway';

// Import the background image
import backgroundImage from '../assets/images/cover.png';

export default function WorkInProgressHero() {
  return (
    <Box
      id="hero"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
      }}
    >
      <Container
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Stack spacing={0} useFlexGap sx={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: 'clamp(2.5rem, 7vw, 3.5rem)',
                color: 'white',
                textShadow: '4px 4px 8px rgba(0,0,0,0.7)',
                fontFamily: 'Raleway, sans-serif',
                fontWeight: 'bold',
              }}
            >
              Pronto vas a poder
            </Typography>
            <Typography
              variant="h1"
              sx={{
                fontSize: 'clamp(2.5rem, 7vw, 3.5rem)',
                color: '#e6b800',
                textShadow: '4px 4px 8px rgba(0,0,0,0.7)',
                fontFamily: 'Raleway, sans-serif',
                fontWeight: 'bold',
              }}
            >
              concertrarte mejor que nunca
            </Typography>
          </Stack>
          <Typography
            textAlign="center"
            sx={{ 
              alignSelf: 'center', 
              width: { sm: '100%', md: '80%' },
              color: 'white',
              textShadow: '2px 2px 4px rgba(0,0,0,0.7)',
              fontFamily: 'Roboto, sans-serif'
            }}
          >
            Nuestra página está en reparaciones. ¡Volvemos pronto!.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}