import React, { useState } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Calendar from "../components/Calendar";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import AppDrawer from "../components/AppDrawer";
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import theme from "../theme";

// Create a custom theme that extends the base theme
const customTheme = createTheme({
  ...theme,
  breakpoints: {
    values: {
      ...theme.breakpoints.values,
      lg: 1400, // Set the 'lg' breakpoint to 1400px
    },
  },
});

function CalendarPage() {
  const [isDrawerRight, setIsDrawerRight] = useState(false);

  const toggleDrawerPosition = () => {
    setIsDrawerRight(!isDrawerRight);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        height: '100vh',
        overflow: 'hidden',
        background: 'linear-gradient(to bottom, #ffffff, #f0f4f8)',
      }}>
        <AppBar 
          position="static" 
          elevation={0} 
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar disableGutters sx={{ bgcolor: "#fff" }}>
            <Navbar /> 
            <IconButton onClick={toggleDrawerPosition}>
              {isDrawerRight ? 'Left' : 'Right'}
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box sx={{ 
          display: 'flex', 
          flexGrow: 1, 
          overflow: 'hidden',
          paddingTop: 2,
          paddingBottom: 2
        }}>
          {!isDrawerRight && (
            <AppDrawer isRight={isDrawerRight} />
          )}

          <Box sx={{ 
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            paddingLeft: { xs: 1, sm: 2, md: 3 },
            paddingRight: { xs: 1, sm: 2, md: 3 },
          }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', lg: 'row' }, // Changed from md to lg
              flexGrow: 1, 
              overflow: 'hidden',
            }}>
              <Box sx={{ 
                order: { xs: 2, lg: 1 }, // Changed from md to lg
                padding: { xs: 1, sm: 2 },
                width: { xs: '100%', lg: '75%' }, // Changed from md to lg
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
                <Paper sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column', 
                  overflow: 'hidden', 
                  borderRadius: { xs: '12px', sm: '16px' },
                  width: '100%', 
                  boxShadow: 3
                }}>
                  <Calendar />
                </Paper>
              </Box>
              <Box sx={{ 
                order: { xs: 1, lg: 2 }, // Changed from md to lg
                width: { xs: '100%', lg: '25%' }, // Changed from md to lg
                flexShrink: 0, 
                overflowY: 'auto',
                padding: { xs: 1, sm: 2 },
                display: { xs: 'none', lg: 'block' }, // Changed from md to lg
              }}>
                <Sidebar />
              </Box>
            </Box>
          </Box>

          {isDrawerRight && (
            <AppDrawer isRight={isDrawerRight} />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default CalendarPage;