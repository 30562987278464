import React from 'react';
import { 
  Backdrop, 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  Box 
} from '@mui/material';

const FirstTimeUserCard = ({ open, onClose }) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Card 
        sx={{ 
          maxWidth: 400, 
          textAlign: 'center',
          bgcolor: 'background.paper',  // Ensure the background color is set
          '&:hover': {
            bgcolor: 'background.paper',  // Maintain the same background color on hover
            opacity: 1,  // Ensure full opacity on hover
          }
        }}
      >
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            ¡Bienvenido a tu página de inicio!
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Una sesión de trabajo nueva comienza cada hora. ¡Recuerda ser puntual! Las sesiones se cierran 10 minutos después de la hora de inicio.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" onClick={onClose}>
              Entrar
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Backdrop>
  );
};

export default FirstTimeUserCard;